import { Alert, Box, CssBaseline, Snackbar, SnackbarCloseReason } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigation, useOutlet } from "react-router-dom";
import "./App.css";
import { ModalLoader } from "./components/common/Loaders";
import NavigationFramework from "./components/common/NavigationFramework";
import { ApplicationThemeProvider } from "./components/theme";
import {
  ApplicationActionTypes,
  ApplicationContextProvider,
  useApplication,
  useApplicationDispatch,
} from "./state/applicationstate";
import { OrganizationContextProvider } from "./state/organizationstate";
import { ProjectContextProvider } from "./state/projectstate";
import { Apis } from "./api/apis";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { FetchStatus } from "./api/types";

function Main() {
  const applicationDispatch = useApplicationDispatch();
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { state: navState } = useNavigation();
  const location = useLocation();
  const { notifications } = useApplication();
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(FetchStatus.idle);

  useEffect(() => {
    const { token, refreshToken } = location.state || {};
    if (token && refreshToken) {
      applicationDispatch({
        type: ApplicationActionTypes.login,
        token,
        refreshToken,
      });
    }
  }, [applicationDispatch, location.state, navigate]);

  const onCloseNotification = useCallback(
    (id: string, event: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
      if (reason === "clickaway") {
        return;
      }
      applicationDispatch({
        type: ApplicationActionTypes.removeNotification,
        id,
      });
    },
    [applicationDispatch]
  );

  useEffect(() => {
    Apis.shared().globalStatusFn = (status: FetchStatus) => {
      setFetchStatus(status);
    };
  }, []);

  const note = notifications && notifications.length > 0 ? notifications[0] : undefined;

  return (
    <Box sx={{ height: "100%" }}>
      <OrganizationContextProvider>
        <NavigationFramework>
          <ProjectContextProvider>
            <CssBaseline />
            {outlet}
            <ModalLoader active={navState === "loading" || fetchStatus !== FetchStatus.idle} />
          </ProjectContextProvider>
        </NavigationFramework>
      </OrganizationContextProvider>
      {note && (
        <Snackbar
          open={true}
          onClose={(e, reason) => {
            onCloseNotification(note.id!, e, reason);
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={note.type} variant="filled" onClose={(e) => onCloseNotification(note.id!, e)}>
            {note.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

export function ThemedApp() {
  const { uiTheme } = useApplication();
  return (
    <ApplicationThemeProvider uiTheme={uiTheme}>
      <Main />
    </ApplicationThemeProvider>
  );
}

export function App() {
  return (
    <ApplicationContextProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemedApp />
      </LocalizationProvider>
    </ApplicationContextProvider>
  );
}

export default App;
