import React from "react";
import { Table, TableBody, TableCell, TableRow, Typography, Box, Chip } from "@mui/material";
import { CollectProjectStats } from "../../api/apimodels";
import moment from "moment";
import WarningIcon from "@mui/icons-material/Warning";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

interface ProjectDetailsTableProps {
  project: CollectProjectStats;
  padding: boolean;
  startTime?: Date;
  endTime?: Date;
  onlyComplete?: boolean;
}

interface TableRow {
  label: string;
  content: React.ReactNode;
}

const generateTableRows = (
  project: CollectProjectStats,
  startTime?: Date,
  endTime?: Date,
  onlyComplete?: boolean,
): TableRow[] => {
  const baseRows = [
    {
      label: "ID",
      content: project.project_id,
    },
    {
      label: "Description",
      content: project.description || "No description",
    },
    {
      label: "Conversations",
      content: (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {project.total_conversations} total
          {project.total_conversations_completed !== project.total_conversations &&
            project.total_conversations > 0 &&
            `, ${project.total_conversations_completed} completed (${(
              (project.total_conversations_completed / project.total_conversations) *
              100
            ).toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}%)`}
          {project.total_conversations_completed === 0 && <WarningIcon sx={{ color: "error.main", fontSize: 20 }} />}
        </Box>
      ),
    },
    {
      label: "Start Date",
      content: project.start_date ? moment(project.start_date).format("LLL") : "N/A",
    },
    {
      label: "End Date",
      content: project.end_date ? moment(project.end_date).format("LLL") : "N/A",
    },
    {
      label: "Last Activity",
      content: project.last_activity ? moment(project.last_activity).format("LLL") : "N/A",
    },
    {
      label: "Labels",
      content: (
        <>
          {project.labels &&
            Object.entries(project.labels).map(([key, label]: [string, any]) => (
              <React.Fragment key={key}>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  {label.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.5,
                    mb: 1,
                    maxWidth: "95%",
                    overflowX: "auto",
                    "&::-webkit-scrollbar": { display: "none" },
                    "-ms-overflow-style": "none",
                    scrollbarWidth: "none",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 0.5, minWidth: "min-content" }}>
                    {label.values?.map((value: any) => <Chip key={value.id} label={value.name} size="small" />) || (
                      <Chip label="No values" size="small" />
                    )}
                  </Box>
                </Box>
              </React.Fragment>
            ))}
        </>
      ),
    },
  ];

  // Add filter rows if any filter is provided
  if (startTime || endTime || onlyComplete !== undefined) {
    if (startTime && endTime) {
      const duration = moment.duration(moment(endTime).diff(moment(startTime)));
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();

      let durationText = [];
      if (days > 0) durationText.push(`${days} days`);
      if (hours > 0) durationText.push(`${hours} hours`);
      if (minutes > 0) durationText.push(`${minutes} minutes`);

      baseRows.push({
        label: "🔎 Time Window",
        content: (
          <>
            {moment(startTime).format("LLL")} to {moment(endTime).format("LLL")}
            <Typography variant="body2" color="text.secondary">
              Duration: {durationText.join(", ")}
            </Typography>
          </>
        ),
      });
    }

    baseRows.push({
      label: "🔎 Complete Only",
      content: onlyComplete ? "Yes" : "No",
    });
  }

  return baseRows;
};

export function CollectProjectDetailsTable({
  project,
  padding,
  startTime,
  endTime,
  onlyComplete,
}: ProjectDetailsTableProps) {
  const rows = generateTableRows(project, startTime, endTime, onlyComplete);

  return (
    <Table size="small">
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index}>
            <TableCell
              component="th"
              sx={{
                fontWeight: "bold",
                width: "30%",
                minWidth: "120px",
                pl: padding ? null : 0,
              }}
            >
              {row.label}
            </TableCell>
            <TableCell>{row.content}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
