import { FormControl, FormHelperText, InputLabel, Select, SelectProps } from "@mui/material";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";

type FormSelectProps<T extends FieldValues> = Omit<SelectProps, "name"> & {
  name: Path<T>;
  label?: string;
  helperText?: string;
};

export function FormSelect<T extends FieldValues>({ name, label, helperText, ...props }: FormSelectProps<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl fullWidth error={!!fieldState.error} variant={props.variant}>
          {label && <InputLabel id={`${name}-label`}>{label}</InputLabel>}
          <Select
            {...field}
            {...props}
            labelId={label ? `${name}-label` : undefined}
            error={!!fieldState.error}
          />
          {(helperText || fieldState.error) && (
            <FormHelperText>{fieldState.error?.message || helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
