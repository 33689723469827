import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Dialog,
  IconButton,
  Paper,
  Slide,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { FC, forwardRef, useEffect, useState } from "react";
import { ApiId, DataSamplesResult, DatasetModel } from "../../api/apimodels";
import { Apis } from "../../api/apis";
import { ColumnId } from "../../api/dataconstants";

const FullscrenDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    background: theme.palette.modalDark,
  },
}));

const TopAlignedCell = styled(TableCell)(({ theme }) => ({
  verticalAlign: "top",
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type AllChartsDialogProps = {
  open: boolean;
  projectId: ApiId;
  dataset: DatasetModel;
  nRows: number;
  onClose: () => void;
};

function getColumnSx(column: string): SxProps<Theme> {
  switch (column) {
    case "sub_theme":
    case "all_themes":
    case "all_theme_scores":
    case ColumnId.TEXT_ID:
    case ColumnId.THEME:
    case ColumnId.DATE:
    case ColumnId.TOPICS:
      return { whiteSpace: "pre" };
    case ColumnId.ORIGINAL_TEXT:
    case ColumnId.TRANSLATED_TEXT:
    case ColumnId.TEXT:
      return { minWidth: "40rem" };
  }
  return {};
}

function getColumnText(column: string, text: string): string {
  switch (column) {
    case ColumnId.TEXT_ID:
      return (text ?? "") + "";
    case "sub_theme":
    case "all_themes":
    case "all_theme_scores":
    case ColumnId.TOPICS:
    case ColumnId.THEME:
      try {
        return (text ?? "").split(/[, ]/).join("\n");
      } catch (e: any) {
        console.error(e);
      }
  }
  return text;
}

export const DatasetPreviewModal: FC<AllChartsDialogProps> = ({ open, nRows = 20, projectId, dataset, onClose }) => {
  const [samplesResult, setSamplesResult] = useState<DataSamplesResult>({
    start: 0,
    count: 0,
    total_count: 0,
    rows: [],
  });
  useEffect(() => {
    const loadFn = async () => {
      setSamplesResult(
        await Apis.shared().data.querySamples(projectId, dataset.id!, dataset.filename!, 0, nRows, undefined, true)
      );
    };
    loadFn();
  }, [dataset.filename, dataset.id, nRows, projectId]);

  return (
    <FullscrenDialog open={open} onClose={onClose} fullScreen TransitionComponent={Transition}>
      <AppBar sx={{ position: "sticky" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {dataset.name} / {dataset.filename}
          </Typography>
        </Toolbar>
      </AppBar>

      <TableContainer component={Paper} sx={{ maxHeight: "100%" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {dataset.columns!.map((c) => (
                <TableCell key={c} sx={{ whiteSpace: "pre", ...getColumnSx(c) }}>
                  {c}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {samplesResult.rows.map((r) => (
              <TableRow key={r[ColumnId.TEXT_ID]}>
                {dataset.columns!.map((c) => (
                  <TopAlignedCell key={c} sx={getColumnSx(c)}>
                    {getColumnText(c, r[c])}
                  </TopAlignedCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FullscrenDialog>
  );
};
