import { DateTimePicker } from "@mui/x-date-pickers";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
type FormDateTimePickerProps<T extends FieldValues> = {
    name: Path<T>;
    label: string;
};

export function FormDateTimePicker<T extends FieldValues>({
    name,
    label
}: FormDateTimePickerProps<T>) {
    const { control } = useFormContext<T>();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <DateTimePicker
                    label={label}
                    value={field.value}
                    onChange={field.onChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            )}
        />
    );
}
