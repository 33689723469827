import { Stack, styled, SxProps, Theme, useTheme } from "@mui/material";
import { interpolateRgb } from "d3";
import { CustomChip, CustomChipBase, CustomChipTooltip } from "../../../../components/common/Tag";
import { StableColorMap } from "../../../../util/colormap";

const HeatmapCell = styled(CustomChipBase)(({ theme }) => ({
  borderRadius: 0,
  width: theme.spacing(3.5),
  lineHeight: theme.spacing(3.5),
  textAlign: "center",
  padding: 0,
}));

const Marker = styled(CustomChipBase)(({ theme }) => ({
  borderRadius: theme.spacing(3.5),
  width: theme.spacing(3.5),
  height: theme.spacing(3.5),
  lineHeight: theme.spacing(3.5),
  textAlign: "center",
  padding: 0,
}));

export type TopicChartProps = {
  rows: [string, number][];
  themeTopic: [string, [string, number][] | undefined][];
  themeColorFn: StableColorMap;
  numRows: number;
};

export function TopicThemeChart(props: TopicChartProps) {
  const { rows, numRows, themeTopic, themeColorFn } = props;
  const nRows = rows.slice(0, Math.min(rows.length, numRows)).filter(([, count]) => count > 0);
  const maxValue = nRows.reduce((p: number, [id, value]) => Math.max(value, p), 0);
  const uiTheme = useTheme();
  const topicThemeMaxValue = themeTopic
    .flatMap((theme) => theme[1])
    .filter((v) => !!v)
    .reduce((p, [topic, count]) => Math.max(count, p), 0);

  const themesAndTopics: [string, Record<string, number>][] = themeTopic.map(([theme, topics]) => {
    const topicMap: Record<string, number> = {};
    (topics ?? []).forEach(([t, count]) => {
      topicMap[t] = count;
    });
    return [theme, topicMap];
  });

  const colorFn = interpolateRgb(uiTheme.palette.modalDark, "#FFFFFF");
  const transferFn = (v: number | undefined) => Math.pow((v ?? 0) / topicThemeMaxValue, 0.75);

  function heatmapCellStyle(count: number): SxProps<Theme> {
    const p = transferFn(count);
    return {
      backgroundColor: colorFn(p),
      color: p > 0.75 ? uiTheme.palette.common.black : "",
    };
  }

  return (
    <Stack direction="row" sx={{ minWidth: "100%" }}>
      <Stack direction="column" alignItems="flex-end">
        <Marker sx={{ visibility: "hidden", mb: 1 }} />
        {nRows.map(([id]) => (
          <CustomChip noTooltip key={id} label={id} />
        ))}
      </Stack>
      <Stack direction="column" alignItems="flex-start" sx={{ width: "100%" }}>
        <Marker sx={{ visibility: "hidden", mb: 1 }} />
        {nRows.map(([id, value]) => (
          <CustomChip noTooltip key={id} label={value + ""} sx={{ minWidth: `${(100 * value) / maxValue}%` }} />
        ))}
      </Stack>
      {themeTopic.length > 0 && (
        <Stack direction="column" alignItems="flex-start" sx={{ width: "100%", ml: 4 }}>
          <Stack direction="row">
            {themesAndTopics.map(([th]) => (
              <CustomChipTooltip key={th} title={th.replaceAll("-", " ")} placement="top">
                <Marker sx={{ backgroundColor: themeColorFn(th), mb: 1 }} />
              </CustomChipTooltip>
            ))}
          </Stack>
          {nRows.map(([id, value]) => (
            <Stack direction="row" key={id}>
              {themesAndTopics.map(([th, topicMap]) => (
                <HeatmapCell key={th} sx={heatmapCellStyle(topicMap[id])}>
                  {topicMap[id] > 0 ? topicMap[id] : ""}
                </HeatmapCell>
              ))}
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
