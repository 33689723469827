export enum ColumnId {
  DATE = "date",
  ORIGINAL_ID = "original_id",
  TEXT_ID = "text_id",
  TEXT = "text",
  ORIGINAL_TEXT = "original_text",
  TRANSLATED_TEXT = "translated_text",
  TOPICS = "topics",
  THEME = "theme",
}

export const AllColumns = [ColumnId.TEXT_ID, ColumnId.TEXT, ColumnId.TOPICS, ColumnId.THEME];
