import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Navigate, RouterProvider, createBrowserRouter, redirect } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import OverviewPage from "./pages/OverviewPage";
import ProjectPage from "./pages/project/ProjectPage";
import DatasetsPage from "./pages/dataset/DatasetPage";
import ProcessorsPage from "./pages/processor/ProcessorsPage";
import ExplorationPage from "./pages/explorations/ExplorationsPage";
import PromptsPage from "./pages/prompt/PromptsPage";
import { LoginPage } from "./pages/LoginPage";
import { DatasetDetailsPage } from "./pages/dataset/DatasetDetailsPage";
import { ProcessorDetailsPage } from "./pages/processor/ProcessorDetailsPage";
import { Session } from "./api/sessionstore";
import { Apis } from "./api/apis";
import { environmentType, localBaseUrl, productionBaseUrl, sharedApiConfig } from "./config";
import { ExplorationDetailsPage } from "./pages/explorations/ExplorationsDetailsPage";
import { PromptDetailsPage } from "./pages/prompt/PromptDetailsPage";
import { getCookie } from "./util/cookies";
import { isPathAvailable } from "./state/organizationstate";

import * as Sentry from "@sentry/react";
import { Environments } from "./state/applicationconfig";

Sentry.init({
  dsn: "https://0291c0cecfce2c7dae418adcea8af77e@o4508682318905344.ingest.de.sentry.io/4508812540444752",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [localBaseUrl, productionBaseUrl],
  replaysSessionSampleRate: environmentType === Environments.production ? 0.1 : 1.0, // we send 10% of the production sessions to sentry, and all development sessions
  replaysOnErrorSampleRate: 1.0,
});

function handleLoginCallbackRedirect(): boolean {
  const error = new URL(window.location.href).searchParams.get("auth_error");
  if (error) {
    console.log("handleLoginCallbackRedirect", error);
    // Note: This parameter cannot have the same name as auth_error, because it will create an infinite redirect loop
    window.location.href = "/login?oauth_error=" + error;
    return true;
  }
  const tokenCookie = getCookie("token");
  const refreshTokenCookie = getCookie("refreshToken");
  if (tokenCookie) {
    Session.shared().set(tokenCookie, refreshTokenCookie!);
    // force a reload to ensure the token is set properly
    window.location.href = window.location.pathname === "/login" ? "/" : window.location.pathname;
    return true;
  }
  return false;
}

Session.createShared(Session.getDefaultStorageProvider());
Apis.createShared(sharedApiConfig);

if (!handleLoginCallbackRedirect()) {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/",
      id: "projects",
      element: <App />,
      loader: async () => {
        const projects = await Apis.shared().metadata.fetchProjects();
        return projects;
      },
      errorElement: <ErrorPage />,
      children: [
        {
          path: "overview",
          element: <OverviewPage />,
        },
        {
          path: "project/:projectId",
          id: "project",
          loader: async ({ params }) => {
            return await Apis.shared().project.fetchProject(params.projectId!);
          },
          children: [
            {
              element: <ProjectPage />,
              index: true,
              loader: async ({ params }) => await Apis.shared().project.fetchProject(params.projectId!),
            },
            {
              path: "dataset",
              element: <DatasetsPage />,
              loader: async ({ params }) => {
                if (!isPathAvailable(window.location.pathname, Session.shared().token)) {
                  return redirect("/overview");
                }
                return await Apis.shared().project.fetchDatasets(params.projectId!);
              },
            },
            {
              path: "dataset/:datasetId",
              element: <DatasetDetailsPage />,
              loader: async ({ params }) => {
                if (!isPathAvailable(window.location.pathname, Session.shared().token)) {
                  return redirect("/overview");
                }
                return [
                  await Apis.shared().project.fetchDatasets(params.projectId!, params.datasetId!),
                  await Apis.shared().project.fetchDatasetLabels(params.projectId!, params.datasetId!),
                  await Apis.shared().project.fetchProcessors(params.projectId!),
                ];
              },
            },
            {
              path: "processor",
              element: <ProcessorsPage />,
              loader: async ({ params }) => {
                if (!isPathAvailable(window.location.pathname, Session.shared().token)) {
                  return redirect("/overview");
                }
                return await Apis.shared().project.fetchProcessors(params.projectId!);
              },
            },
            {
              path: "processor/:processorId",
              element: <ProcessorDetailsPage />,
              loader: async ({ params }) => {
                if (!isPathAvailable(window.location.pathname, Session.shared().token)) {
                  return redirect("/overview");
                }
                return await Apis.shared().project.fetchProcessor(params.projectId!, params.processorId!);
              },
            },
            {
              path: "exploration",
              element: <ExplorationPage />,
              loader: async ({ params }) => await Apis.shared().project.fetchExplorations(params.projectId!),
            },
            {
              path: "exploration/:explorationId",
              element: <ExplorationDetailsPage />,
              loader: async ({ params }) => {
                const parallelQueries: any[] = [];
                const exploration = await Apis.shared().project.fetchExploration(
                  params.projectId!,
                  params.explorationId!
                );
                const datasetRootId = exploration.dataset?.root_id ?? exploration.dataset?.id;
                parallelQueries.push(
                  Apis.shared().data.queryStatistics(
                    params.projectId!,
                    exploration.dataset_id,
                    exploration.dataset!.filename!
                  ),
                  Apis.shared().project.fetchDatasetLabels(params.projectId!, datasetRootId!),
                  Apis.shared().project.fetchPrompts(params.projectId!)
                );
                const results = await Promise.all(parallelQueries);
                const [statistics, labels, prompts] = results;
                return { exploration, statistics, labels, prompts };
              },
            },
            {
              path: "prompt",
              element: <PromptsPage />,
              loader: async ({ params, request }) => {
                const sp = new URL(request.url);
                const type = sp.searchParams.get("type") ?? undefined;
                return await Apis.shared().project.fetchPrompts(params.projectId!, type);
              },
            },
            {
              path: "prompt/:promptId",
              element: <PromptDetailsPage />,
              loader: async ({ params }) => {
                const exploration = await Apis.shared().project.fetchPrompt(params.projectId!, params.promptId!);
                return exploration;
              },
            },
          ],
        },
        {
          path: "",
          element: <Navigate to="/overview" />,
        },
      ],
    },
  ]);

  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
