import React from "react";
import { Checkbox, FormControlLabel, FormControlLabelProps } from "@mui/material";
import { useFormContext, FieldValues, Path } from "react-hook-form";
import { Controller } from "react-hook-form";

type FormCheckboxProps<T extends FieldValues> = Omit<FormControlLabelProps, "name" | "control"> & {
  name: Path<T>;
};

export function FormCheckbox<T extends FieldValues>({ name, label, ...props }: FormCheckboxProps<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
              onBlur={field.onBlur}
              name={field.name}
            />
          }
          label={label}
          {...props}
        />
      )}
    />
  );
}
