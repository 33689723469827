import { createSignedRefreshLinkFn } from "./helpers";
import { ISession, Session } from "./sessionstore";
import { ApiConfig } from "../state/applicationconfig";
import { HttpClient } from "./httpclient";
import { AuthApi } from "./impl/auth";
import { DataApi } from "./impl/data";
import { LangApi } from "./impl/lang";
import { MetadataApi } from "./impl/metadata";
import { ProjectApi } from "./impl/project";
import { FetchStatus, IAuthApi, IDataApi, ILangApi, IMetadataApi, IProjectApi } from "./types";
import { createAuthRequestInterceptor, createAuthResponseInterceptor, createApiErrorInterceptor, createStatusErrorInterceptor } from "./interceptors";
import { extractFromUrlPath } from "../util/url";
import { TokenRefreshManager } from "./token";


function projectIdExtractor(url: string): number | undefined {
  return extractFromUrlPath(url, {
    segment: "project",
    transform: (value) => {
      const num = Number(value);
      return !isNaN(num) ? num : undefined;
    },
  });
}

export class Apis {
  private _config: ApiConfig;
  private _auth: IAuthApi;
  private _metadata: IMetadataApi;
  private _project: IProjectApi;
  private _data: IDataApi;
  private _lang: ILangApi;
  private _globalStatusFn: (status: FetchStatus) => void = () => {};

  get auth(): IAuthApi {
    return this._auth;
  }
  get metadata(): IMetadataApi {
    return this._metadata;
  }
  get project(): IProjectApi {
    return this._project;
  }
  get data(): IDataApi {
    return this._data;
  }
  get lang(): ILangApi {
    return this._lang;
  }

  public set globalStatusFn(fn: (status: FetchStatus) => void) {
    this._globalStatusFn = fn;
  }

  constructor(config: ApiConfig) {
    this._config = config;
    const endpoints = this._config.endpoints;    
    const statusFn = (status: FetchStatus) => {
      this._globalStatusFn(status);
    };

    // Create error interceptors
    const errorInterceptors = [
      createStatusErrorInterceptor(statusFn),  // Handle status first
      createApiErrorInterceptor()              // Then convert to API errors
    ];

    // Create auth client first without any interceptors
    const authClient = new HttpClient(endpoints.auth, [], [], errorInterceptors);
    this._auth = new AuthApi(authClient);

    const refreshManager = new TokenRefreshManager();

    const tokenRefreshFn = async (session: ISession) => {
      // We use the window location instead of the context.request.url 
      // since all reqeuests we make may not contain projectId and we 
      // do not want to refresh the token multiple times
      const projectId = projectIdExtractor(window.location.href);
      await refreshManager.refreshToken(this._auth, session, {projectId});
    }

    // Create interceptors after auth is initialized
    const requestInterceptors = [createAuthRequestInterceptor(Session.shared(), statusFn)];
    const responseInterceptors = [createAuthResponseInterceptor(Session.shared(), tokenRefreshFn, statusFn)];

    // Create other clients with all interceptors
    this._project = new ProjectApi(
      new HttpClient(endpoints.project, requestInterceptors, responseInterceptors, errorInterceptors),
      createSignedRefreshLinkFn(this._auth, Session.shared())
    );

    this._data = new DataApi(new HttpClient(endpoints.data, requestInterceptors, responseInterceptors, errorInterceptors));
    this._metadata = new MetadataApi(new HttpClient(endpoints.metadata, requestInterceptors, responseInterceptors, errorInterceptors));
    this._lang = new LangApi(new HttpClient(endpoints.lang, requestInterceptors, responseInterceptors, errorInterceptors));
  }

  private static _sharedInstance: Apis | null;
  public static shared(): Apis {
    return this._sharedInstance!;
  }
  public static createShared(config: ApiConfig) {
    this._sharedInstance = new Apis(config);
  }
}
