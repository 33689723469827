import { Menu, MenuItem, IconButton, Avatar, FormControlLabel, Switch, Tooltip, Divider } from "@mui/material";
import { useState } from "react";
import { UITheme } from "../theme";
import { OrganizationSelector } from "./OrganizationSelector";
import LogoutIcon from "@mui/icons-material/Logout";
import { ApplicationActionTypes, useApplicationDispatch } from "../../state/applicationstate";

interface UserMenuProps {
  uiTheme: UITheme;
  maskTexts: boolean;
  onThemeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onMaskTextsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  organizationName?: string;
}

// TODO: Add organization info support (should not be part of the token). Then we can get color, logos and whatnot.

export function UserMenu({
  uiTheme,
  maskTexts,
  onThemeChange,
  onMaskTextsChange,
  organizationName = "?",
}: UserMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const applicationDispatch = useApplicationDispatch();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    applicationDispatch({ type: ApplicationActionTypes.logout });
    window.location.href = "/login"; // Force reload
    handleMenuClose();
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <OrganizationSelector />
      <Tooltip title={organizationName}>
        <IconButton
          onClick={handleMenuClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={menuOpen ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          aria-label={organizationName}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              bgcolor: "primary.main",
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={menuOpen}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <FormControlLabel
            label="Dark mode"
            control={<Switch checked={uiTheme === UITheme.dark} onChange={onThemeChange} />}
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel label="Mask texts" control={<Switch checked={maskTexts} onChange={onMaskTextsChange} />} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={{ mr: 2 }} />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
