/**
 * Abbreviates a string by keeping first and last n characters with ellipsis in between
 * @param str The input string to abbreviate
 * @param headCount Number of characters to keep from start (default: 4)
 * @param tailCount Number of characters to keep from end (default: 4)
 * @param ellipsis The ellipsis string to use (default: '...')
 * @returns Abbreviated string or original if too short
 */
export function abbreviateStringFromEnds(
  str: string,
  headCount: number = 4,
  tailCount: number = 4,
  ellipsis: string = "...",
): string {
  if (str.length <= headCount + tailCount) {
    return str;
  }

  const head = str.slice(0, headCount);
  const tail = str.slice(-tailCount);

  return `${head}${ellipsis}${tail}`;
}

/**
 * Shortens a string to a maximum length using ellipsis
 * @param str The input string to shorten
 * @param maxLength Maximum length of the output string including ellipsis
 * @param ellipsis The ellipsis string to use (default: '...')
 * @returns Shortened string or original if already shorter than maxLength
 */
export function abbreviateString(str: string, maxLength: number, ellipsis: string = "..."): string {
  if (str.length <= maxLength) {
    return str;
  }

  const charsToShow = maxLength - ellipsis.length;
  if (charsToShow <= 0) {
    return ellipsis.slice(0, maxLength);
  }

  const headCount = Math.ceil(charsToShow / 2);
  const tailCount = Math.floor(charsToShow / 2);

  const head = str.slice(0, headCount);
  const tail = str.slice(-tailCount);

  return `${head}${ellipsis}${tail}`;
}

/**
 * Formats a string to create a concise, meaningful representation by combining priority markers
 * and semantic context.
 *
 * Rules:
 * 1. If the first word is ≤ 3 characters (e.g., "*", "⭐️"):
 *    - Preserve it as a priority marker
 *    - Format: "{marker} {context}"
 *
 * 2. Otherwise:
 *    - Use entire string as context
 *    - Format: "{context}"
 *
 * 3. The context portion is always limited to 8 characters, using ellipsis if needed
 *
 * Examples:
 * - "* hello world"     → "* hell...rld"
 * - "⭐️ hello world"    → "⭐️ hell...rld"
 * - "hello world"       → "hell...rld"
 *
 * @param input The string to format
 * @returns Formatted string following the above rules
 */
export function inferDefaultName(input: string, contextLength: number = 8): string {
  // Split into segments
  const segments = input.split(" ");
  if (segments.length === 0) return "";

  // Check if first segment is 3 chars or shorter
  const firstSegment = segments[0];
  const includeFirstSegment = firstSegment.length <= 3;

  // Handle the last piece (semantic context)
  let lastPiece = "";
  if (includeFirstSegment) {
    // Skip the first segment and join the rest
    lastPiece = segments.slice(1).join(" ");
  } else {
    // Use all segments
    lastPiece = segments.join(" ");
  }

  // Ensure last piece is not longer than 8 characters
  const formattedLastPiece = lastPiece.length > contextLength ? abbreviateString(lastPiece, contextLength) : lastPiece;

  // Construct final result
  return includeFirstSegment ? `${firstSegment} ${formattedLastPiece}` : formattedLastPiece;
}

export const getInitials = (name: string): string => {
  const words = name.split(" ");
  return (
    words.length === 1
      ? name.slice(0, 2) // Take first two letters for single word
      : words
          .slice(0, 2)
          .map((word) => word.charAt(0))
          .join("")
  ).toUpperCase();
};
