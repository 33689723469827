import { PieChart } from "@mui/x-charts";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  Stack,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { CollectProjectStats } from "../../api/apimodels";
import { CollectProjectDetailsTable } from "./CollectProjectDetailsTable";
import moment from "moment";

export interface LabelValue {
  name: string;
  value: string;
  order: number;
  description: string;
}

export interface LabelField {
  id: string;
  name: string;
  order: number;
  description: string;
  type: string;
  values: LabelValue[];
}

export interface CategoricalStatistic {
  question_id: string;
  question_text: string;
  total_answers: number;
  absolute_counts: Record<string, number>;
  relative_counts: Record<string, number>;
  descriptor: LabelField;
}

export interface CollectStatistics {
  categorical_stats: CategoricalStatistic[];
}

interface CollectStatisticsDialogProps {
  statistics: CollectStatistics;
  project: CollectProjectStats;
  open: boolean;
  onClose: () => void;
  startTime?: Date;
  endTime?: Date;
  onlyComplete: boolean;
}

export function CollectStatisticsDialog({
  statistics,
  project,
  open,
  onClose,
  startTime,
  endTime,
  onlyComplete,
}: CollectStatisticsDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {project.organization_name}: {project.project_id}
        <IconButton aria-label="close" onClick={onClose} sx={{ color: (theme) => theme.palette.grey[500] }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ px: 0 }}>
        <Stack gap={3}>
          <Box>
            <Typography variant="h5" gutterBottom px={2}>
              Project Details
            </Typography>
            <CollectProjectDetailsTable
              project={project}
              padding
              startTime={startTime}
              endTime={endTime}
              onlyComplete={onlyComplete}
            />
          </Box>
          <Box>
            <Typography variant="h5" gutterBottom px={2}>
              Categorical Statistics
            </Typography>
            {statistics.categorical_stats.map((stat) => {
              // Create a mapping of value codes to their display names
              const valueMap = new Map(stat.descriptor.values.map((v) => [v.value, v]));

              // Sort dataset by the order specified in descriptor, using relative counts
              const dataset = Object.entries(stat.absolute_counts)
                .map(([value, count]) => ({
                  category: valueMap.get(value)?.name || value,
                  value: count,
                  relativeValue: stat.relative_counts[value] * 100, // Convert to percentage
                  description: valueMap.get(value)?.description || "",
                  order: valueMap.get(value)?.order || 0,
                }))
                .sort((a, b) => b.value - a.value); // Sort by absolute count descending

              return (
                <Box key={stat.question_id} sx={{ mb: 4 }}>
                  <Box px={2}>
                    <Typography variant="h6" gutterBottom>
                      {stat.question_text}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      {stat.descriptor.description}
                    </Typography>
                    <Box sx={{ width: "100%", height: 220, maxWidth: 500 }}>
                      <PieChart
                        dataset={dataset}
                        series={[
                          {
                            data: dataset.map((d) => ({
                              label: d.category,
                              value: d.value,
                            })),
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                  <Table size="small" sx={{ mt: 2, maxWidth: 500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell align="right">Count</TableCell>
                        <TableCell align="right">Percentage</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataset.map((row) => (
                        <TableRow key={row.category}>
                          <TableCell>{row.category}</TableCell>
                          <TableCell align="right">{row.value}</TableCell>
                          <TableCell align="right">{row.relativeValue.toFixed(1)}%</TableCell>
                        </TableRow>
                      ))}
                      <TableRow sx={{ "& td": { fontWeight: "bold" } }}>
                        <TableCell>Total</TableCell>
                        <TableCell align="right">{stat.total_answers}</TableCell>
                        <TableCell align="right">100.0%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              );
            })}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
