import { JobStatusEnum } from "../api/apimodels";
import { HelpOutline, CheckCircle, Cancel, HourglassEmpty, AccessTime } from "@mui/icons-material";
import { SxProps } from "@mui/material";
export function getJobStatusLabel(status: JobStatusEnum) {
  return {
    [JobStatusEnum.UNSPECIFIED]: "Unspecified",
    [JobStatusEnum.SUCCEEDED]: "Succeeded",
    [JobStatusEnum.FAILED]: "Failed",
    [JobStatusEnum.CANCELLED]: "Cancelled",
    [JobStatusEnum.RUNNING]: "Running",
    [JobStatusEnum.PENDING]: "Pending",
  }[status];
}

export function getJobStatusEmoji(status: JobStatusEnum, sx?: SxProps) {
  const icons = {
    [JobStatusEnum.UNSPECIFIED]: <HelpOutline color="action" sx={sx} />,
    [JobStatusEnum.SUCCEEDED]: <CheckCircle color="success" sx={sx} />,
    [JobStatusEnum.FAILED]: <Cancel color="error" sx={sx} />,
    [JobStatusEnum.CANCELLED]: <Cancel color="error" sx={sx} />,
    [JobStatusEnum.RUNNING]: <HourglassEmpty color="warning" sx={sx} />,
    [JobStatusEnum.PENDING]: <AccessTime color="info" sx={sx} />,
  };
  return icons[status];
}
