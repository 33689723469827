import { NotAuthenticatedError } from "./types";
import { IAuthApi, SignedLinkFn } from "./types";
import { ISession } from "./sessionstore";
import { AuthResult, decodeToken, isAuth } from "./token";

export function standardGetOptions(additionalOptions: RequestInit = {}): RequestInit {
  let opts: any = {
    method: "GET",
    //mode: 'no-cors',
    redirect: "follow",
  };
  Object.keys(additionalOptions).forEach((k: string) => {
    opts[k] = (additionalOptions as any)[k];
  });
  return opts;
}

export function standardPostOptions(additionalOptions: RequestInit = {}): RequestInit {
  let opts: any = {
    method: "POST",
    //mode: 'no-cors',
    redirect: "follow",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  };
  Object.keys(additionalOptions).forEach((k: string) => {
    opts[k] = (additionalOptions as any)[k];
  });
  return opts;
}

export function standardPutOptions(additionalOptions: RequestInit = {}): RequestInit {
  let opts: any = {
    method: "PUT",
    //mode: 'no-cors',
    redirect: "follow",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
  };
  Object.keys(additionalOptions).forEach((k: string) => {
    opts[k] = (additionalOptions as any)[k];
  });
  return opts;
}

export function standardDeleteOptions(additionalOptions: RequestInit = {}): RequestInit {
  let opts: any = {
    method: "DELETE",
    //mode: 'no-cors',
    redirect: "follow",
    cache: "no-cache",
  };
  Object.keys(additionalOptions).forEach((k: string) => {
    opts[k] = (additionalOptions as any)[k];
  });
  return opts;
}

export function createSignedRefreshLinkFn(authApi: IAuthApi, session: ISession): SignedLinkFn {
  if (!session.refreshToken) {
    return () => {
      throw new NotAuthenticatedError("Not authorized", window.location.href);
    };
  }
  return (url: string) => {
    let decoded = session.token ? decodeToken(session.token) : undefined;
    let u = new URL(url);
    const options: any = {};
    let headers: any = {};
    headers["Authorization"] = `Bearer ${session.token}`;
    options.headers = headers;

    if (!decoded || isAuth(decoded) !== AuthResult.ok) {
      return authApi
        .tokenRefresh(decoded?.payload?.org, session.refreshToken!)
        .then(({ token: newToken, refreshToken: newRefreshToken }) => {
          session.refreshToken = newRefreshToken;
          session.token = newToken;
          let u = new URL(url);
          u.searchParams.set("t", newToken);
          return u.toString();
        })
        .catch((error) => {
          console.warn(error);
          throw new NotAuthenticatedError("Token refresh failed - not authorized", window.location.href);
        });
    } else {
      u.searchParams.set("t", session.token!);
      return Promise.resolve(u.toString());
    }
  };
}
