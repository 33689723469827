import { ISession } from "./sessionstore";
import { IAuthApi } from "./types";

type DecodedToken = {
  header: any;
  payload: any;
};

// TODO: Move token parsing to api call instead and store both encoded and decoded info in Application State

function parseb64(b64: string): string {
  return b64.replace(/-/g, "+").replace(/_/g, "/");
}

export function decodeToken(token: string): DecodedToken | undefined {
  try {
    const [header, payload] = token
      .split(".")
      .slice(0, 2)
      .map(parseb64)
      .map(window.atob)
      .map((str) => JSON.parse(str));
    return { header, payload };
  } catch (e) {
    return undefined;
  }
}

export enum AuthResult {
  invalid,
  shouldRefresh,
  ok,
}

export function isAuth(decoded: DecodedToken, expirationMargin: number = 120): AuthResult {
  try {
    const expire = decoded.payload.exp;
    const now = Date.now() / 1000.0;
    const diff = expire - now;
    if (diff > expirationMargin) {
      return AuthResult.ok;
    } else if (diff > 0) {
      return AuthResult.shouldRefresh;
    }
    return AuthResult.invalid;
  } catch (e) {
    console.error(e);
    return AuthResult.invalid;
  }
}

export type RefreshTokenParameters = {
  projectId?: number;
}

/**
 * This class ensures that only one token refresh is in progress at a time.
 */
export class TokenRefreshManager {
  private refreshPromise: Promise<{ token: string; refreshToken: string }> | null = null;

  async refreshToken(
    authApi: IAuthApi,
    session: ISession,
    parameters: RefreshTokenParameters
  ): Promise<{ token: string; refreshToken: string }> {
    if (!this.refreshPromise) {
      console.log('Refreshing token with parameters:', parameters);
      this.refreshPromise = (async () => {
        try {
          const result = await authApi.tokenRefresh(undefined, session.refreshToken!, parameters.projectId);
          console.log("AAAAA")
          session.token = result.token;
          session.refreshToken = result.refreshToken;
          
          return result;
        } finally {
          this.refreshPromise = null;
        }
      })();
    } else {
      console.log('Token refresh already in progress');
    }
    return this.refreshPromise;
  }
}
