import { ApiConfig, Environments } from "./state/applicationconfig";

export const productionBaseUrl = "https://canucci-metadata-api-635645546819.europe-north1.run.app";
export const localBaseUrl = "http://localhost:8000";

export const apiConfig: Record<Environments, ApiConfig> = {
  [Environments.production]: {
    endpoints: {
      metadata: `${productionBaseUrl}/project/api`,
      auth: `${productionBaseUrl}/auth/api`,
      project: `${productionBaseUrl}/project/api`,
      data: `${productionBaseUrl}/data/api`,
      lang: `${productionBaseUrl}/lang/api`,
      job: `${productionBaseUrl}/job/api`,
    },
  },
  [Environments.local]: {
    endpoints: {
      metadata: `${localBaseUrl}/project/api`,
      auth: `${localBaseUrl}/auth/api`,
      project: `${localBaseUrl}/project/api`,
      data: `${localBaseUrl}/data/api`,
      lang: `${localBaseUrl}/lang/api`,
      job: `${localBaseUrl}/job/api`,
    },
  },
};

export const environmentType = (window as any)["__cd_env"] === "prod" ? Environments.production : Environments.local;

export const sharedApiConfig = (window as any)["__cd_env"] === "prod" ? apiConfig.production : apiConfig.local;
