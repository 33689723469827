import React from "react";
import { FormGroup, FormGroupProps, FormControlLabel, Checkbox } from "@mui/material";
import { useFormContext, FieldValues, Path } from "react-hook-form";
import { Controller } from "react-hook-form";
interface Option {
  id: string;
  label: string;
}

type FormCheckboxGroupProps<T extends FieldValues> = Omit<FormGroupProps, "name"> & {
  name: Path<T>;
  options: Option[];
};

export function FormCheckboxGroup<T extends FieldValues>({ name, options, ...props }: FormCheckboxGroupProps<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormGroup {...props}>
          {options.map((option) => (
            <FormControlLabel
              key={option.id}
              control={
                <Checkbox
                  checked={field.value.includes(option.id)}
                  onChange={(e) => {
                    const newValue = e.target.checked
                      ? [...field.value, option.id]
                      : field.value.filter((id: string) => id !== option.id);
                    field.onChange(newValue);
                  }}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
      )}
    />
  );
}
