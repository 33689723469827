import { boolStr } from "../../util/params";
import { ApiId, DataHeatmapResult, DataSamplesResult, DataStatisticsResult, ExplorationFilter } from "../apimodels";
import { standardPostOptions } from "../helpers";
import { HttpClient } from "../httpclient";
import { FetchFn, IDataApi } from "../types";

export class DataApi implements IDataApi {
  private baseUrl: string;
  private fetchFn: FetchFn;
  public ver: number;

  constructor(httpClient: HttpClient) {
    this.baseUrl = httpClient.baseUrl;
    this.fetchFn = (input, init) => httpClient.fetch(input, init);
    this.ver = Math.random() * 10000000;
  }

  async queryStatistics(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    filter?: ExplorationFilter,
    includeLabels?: boolean,
    includeThemeTopicFrequency?: boolean,
    includeWordsStats?: boolean
  ): Promise<DataStatisticsResult> {
    const u = new URL(`${this.baseUrl}/query/statistics`);
    u.searchParams.set("project_id", `${projectId}`);
    u.searchParams.set("dataset_id", `${datasetId}`);
    u.searchParams.set("filename", filename);
    u.searchParams.set("include_labels", boolStr(includeLabels));
    u.searchParams.set("include_theme_topic_freq", boolStr(includeThemeTopicFrequency));
    u.searchParams.set("include_word_stats", boolStr(includeWordsStats));
    const url = u.toString();
    const response = await this.fetchFn(url, standardPostOptions({ body: JSON.stringify({ filter: filter ?? null }) }));
    const data = await response.json();
    return data as DataStatisticsResult;
  }

  async queryHeatmap(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    xAxis: [string, string],
    yAxis: [string, string],
    filter?: ExplorationFilter,
    xSort?: Record<string, number>,
    ySort?: Record<string, number>
  ): Promise<DataHeatmapResult> {
    const u = new URL(`${this.baseUrl}/query/statistics/heatmap`);
    u.searchParams.set("project_id", `${projectId}`);
    u.searchParams.set("dataset_id", `${datasetId}`);
    u.searchParams.set("filename", filename);
    const payload = {
      filter: filter ?? null,
      heatmap: { x_axis: xAxis, y_axis: yAxis, x_sort: xSort ?? {}, y_sort: ySort ?? {} },
    };
    const url = u.toString();
    const response = await this.fetchFn(url, standardPostOptions({ body: JSON.stringify(payload) }));
    const data = await response.json();
    return data as DataHeatmapResult;
  }

  async querySamples(
    projectId: ApiId,
    datasetId: ApiId,
    filename: string,
    start: number,
    count: number,
    filter?: ExplorationFilter,
    allColumns?: boolean
  ): Promise<DataSamplesResult> {
    const u = new URL(`${this.baseUrl}/query/samples`);
    u.searchParams.set("project_id", `${projectId}`);
    u.searchParams.set("dataset_id", `${datasetId}`);
    u.searchParams.set("filename", filename);
    u.searchParams.set("all_columns", boolStr(allColumns));
    const url = u.toString();
    const response = await this.fetchFn(
      url,
      standardPostOptions({
        body: JSON.stringify({
          filter: filter ?? null,
          start,
          count,
        }),
      })
    );
    const data = await response.json();
    return data as DataSamplesResult;
  }
}
