import { zodResolver } from "@hookform/resolvers/zod";
import { Check as CheckIcon, CloudDownload as CloudDownloadIcon, Warning as WarningIcon } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { CollectProjectStats, DatasetModel } from "../../api/apimodels";
import { Apis } from "../../api/apis";
import { CollectProjectDetailsTable } from "./CollectProjectDetailsTable";
import { FormCheckboxGroup } from "./form/FormCheckboxGroup";
import { FormSelect } from "./form/FormSelect";
import { FormTextField } from "./form/FormTextField";
import { LoaderButton } from "./LoaderButton";
import { CreateFormHeader } from "./ResourceCreateForm";
import { StandardCard } from "./StandardCard";
import { FormDateTimePicker } from "./form/FormDateTimePicker";
import { FormCheckbox } from "./form/FormCheckbox";
import { CollectStatisticsDialog } from "./CollectStatisticsDialog";

interface DatasetFromCollectProps {
  projectId: number;
  onSuccess: (dataset: DatasetModel) => void;
}

const formatDateRange = (startDate: string, endDate: string): string => {
  const start = moment(startDate);
  const end = moment(endDate);

  const isStartTimeSignificant = !start.format("HH:mm").match(/(00:00|23:59)/);
  const isEndTimeSignificant = !end.format("HH:mm").match(/(00:00|23:59)/);

  if (start.isSame(end, "day")) {
    const dateStr = `${start.format("dddd YYYY-MM-DD")}`;
    if (isStartTimeSignificant && isEndTimeSignificant) {
      return `${dateStr} (${start.format("HH:mm")} - ${end.format("HH:mm")})`;
    }
    return dateStr;
  }

  const startStr = `${start.format("dddd YYYY-MM-DD")}${isStartTimeSignificant ? " " + start.format("HH:mm") : ""}`;
  const endStr = `${end.format("dddd YYYY-MM-DD")}${isEndTimeSignificant ? " " + end.format("HH:mm") : ""}`;
  return `${startStr} - ${endStr}`;
};

export const datasetFromCollectSchema = z.object({
  name: z.string().min(1, "Name is required").describe("Dataset Name"),
  description: z.string().describe("Dataset Description"),
  onlyComplete: z.boolean().describe("Only completed conversations"),
  textTargetColumn: z.enum(["text", "original_text", "translated_text"]).describe("Text Target Column"),
  selectedQuestions: z.array(z.string()).min(1, "Please select at least one question").describe("Questions"),
  collectProjectId: z.string().min(1, "Please select a project").describe("Project"),
  startTime: z.coerce.date().optional().describe("Start Time"),
  endTime: z.coerce.date().optional().describe("End Time"),
});

export type DatasetFromCollectFormData = z.infer<typeof datasetFromCollectSchema>;

interface FormSectionProps {
  title: string;
  children: React.ReactNode;
}

const FormSection = ({ title, children }: FormSectionProps) => (
  <Stack direction="column" spacing={2}>
    <Typography
      variant="subtitle1"
      gutterBottom
      sx={{
        fontWeight: 500,
        color: "text.primary",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      {title}
    </Typography>
    <Box sx={{ pl: 2 }}>{children}</Box>
  </Stack>
);

export function DatasetFromCollectForm({ projectId, onSuccess }: DatasetFromCollectProps) {
  const [collectProjects, setCollectProjects] = useState<CollectProjectStats[]>([]);
  const [loading, setLoading] = useState(false);
  const [projectLoadError, setProjectLoadError] = useState<string | null>(null);
  const [importing, setImporting] = useState(false);
  const [statistics, setStatistics] = useState<any>(null);
  const [fetchingStats, setFetchingStats] = useState(false);

  const formMethods = useForm<DatasetFromCollectFormData>({
    resolver: zodResolver(datasetFromCollectSchema),
    defaultValues: {
      name: "",
      description: "",
      textTargetColumn: "original_text",
      selectedQuestions: [],
      collectProjectId: "",
      onlyComplete: true,
    },
    mode: "onChange",
  });

  const { watch, setValue } = formMethods;
  const collectProjectId = watch("collectProjectId");
  const selectedProject = useMemo(
    () => collectProjects.find((p) => p.project_id === collectProjectId),
    [collectProjects, collectProjectId],
  );

  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      setProjectLoadError(null);
      try {
        const projects = await Apis.shared().project.fetchCollectProjects(projectId);
        setCollectProjects(projects);
      } catch (e: any) {
        const errorMessage = e?.response?.data?.detail || e.message || "Unknown error";
        setProjectLoadError(`Failed to load projects: ${errorMessage}`);
        console.error(e);
      }
      setLoading(false);
    };
    fetchProjects();
  }, [projectId]);

  const handleProjectSelect = (project: CollectProjectStats) => {
    formMethods.clearErrors();
    const name = [
      project.organization_name,
      project.name,
      project.start_date && moment(project.start_date).format("YYYY-MM-DD"),
      `(${project.project_id})`,
      "📥",
    ]
      .filter(Boolean)
      .join(" - ");

    setValue("name", name);
    setValue("description", project.description || "");
    setValue("collectProjectId", project.project_id);
    setValue("selectedQuestions", Object.keys(project.text_questions) || []);
    setValue("startTime", new Date(project.start_date));
    setValue("endTime", new Date(project.end_date));

    formMethods.trigger();
  };

  const onSubmit = async (data: DatasetFromCollectFormData) => {
    if (!selectedProject) return;

    console.log("onSubmit", {
      original: data.startTime,
      getTime: data.startTime?.getTime(),
      toISOString: data.startTime?.toISOString(),
    });

    const selectedStart = data.startTime?.getTime() || 0;
    const selectedEnd = data.endTime?.getTime() || 0;
    const projectStart = new Date(selectedProject.start_date).getTime();
    const projectEnd = new Date(selectedProject.end_date).getTime();

    if (selectedStart < projectStart || selectedEnd > projectEnd) {
      formMethods.setError("root", {
        type: "validation",
        message: "Selected date range must be within project start and end dates",
      });
      return;
    }

    setImporting(true);
    try {
      const dataset = await Apis.shared().project.importCollectDataset(projectId, {
        collect_project_id: data.collectProjectId,
        name: data.name.trim(),
        only_complete: data.onlyComplete,
        description: data.description.trim(),
        text_target_column: data.textTargetColumn,
        text_question_template_ids: data.selectedQuestions,
        start_time: data.startTime?.toISOString(),
        end_time: data.endTime?.toISOString(),
      });
      onSuccess?.(dataset);
    } catch (error: any) {
      formMethods.setError("root", {
        type: "submit",
        message: error?.details?.detail || error.message || "Failed to import dataset",
      });
    } finally {
      setImporting(false);
    }
  };

  const handleFetchStatistics = async () => {
    if (!selectedProject) return;

    const startTime = formMethods.getValues("startTime");
    const endTime = formMethods.getValues("endTime");

    setFetchingStats(true);
    try {
      const stats = await Apis.shared().project.fetchCollectProjectStatistics(projectId, {
        collect_project_id: selectedProject.project_id,
        only_complete: formMethods.getValues("onlyComplete"),
        start_time: startTime?.toISOString(),
        end_time: endTime?.toISOString(),
      });
      setStatistics(stats);
    } catch (error: any) {
      formMethods.setError("root", {
        type: "statistics",
        message: error?.details?.detail || error.message || "Failed to fetch statistics",
      });
    } finally {
      setFetchingStats(false);
    }
  };

  const statisticsDialogOpen = statistics !== null;

  const handleCloseStatistics = () => {
    setStatistics(null);
  };

  return (
    <StandardCard sx={{ p: 2 }}>
      <FormProvider {...formMethods}>
        <Box
          component="form"
          onSubmit={formMethods.handleSubmit(onSubmit)}
          sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}
        >
          <CreateFormHeader title="Import from Collect Service" icon={<CloudDownloadIcon />} />

          {formMethods.formState.errors.root && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {formMethods.formState.errors.root.message}
            </Alert>
          )}

          <Stack spacing={3}>
            {/* Project Selection Section */}
            <FormSection title="Select Project">
              {loading && <CircularProgress size={20} />}
              {projectLoadError && <Alert severity="error">{projectLoadError}</Alert>}
              {!loading && !projectLoadError && (
                <List sx={{ maxHeight: "300px", overflow: "auto", mx: -2 }}>
                  {collectProjects.map((project: CollectProjectStats) => (
                    <ListItemButton
                      key={project.project_id}
                      onClick={() => handleProjectSelect(project)}
                      selected={selectedProject?.project_id === project.project_id}
                      sx={{ cursor: "pointer" }}
                    >
                      <ListItemText
                        primary={project.name}
                        secondary={
                          <>
                            <Typography
                              component="div"
                              variant="body2"
                              fontWeight="bold"
                              sx={{ display: "flex", alignItems: "center", gap: 1 }}
                            >
                              {project.organization_name || "No organization"}
                              <Typography component="span" variant="body2" color="GrayText">
                                {project.project_id}
                              </Typography>
                              {project.total_conversations_completed === 0 && (
                                <WarningIcon sx={{ color: "error.main", fontSize: 16 }} />
                              )}
                            </Typography>

                            <Typography component="div" variant="body2" color="textSecondary">
                              {formatDateRange(project.start_date, project.end_date)}
                            </Typography>
                          </>
                        }
                      />
                      <ListItemIcon>{selectedProject?.project_id === project.project_id && <CheckIcon />}</ListItemIcon>
                    </ListItemButton>
                  ))}
                </List>
              )}
            </FormSection>

            {selectedProject && (
              <>
                <FormSection title="Project Details">
                  <CollectProjectDetailsTable project={selectedProject} padding={false} />
                </FormSection>

                <FormSection title="Dataset Information">
                  <Stack spacing={2}>
                    <FormTextField name="name" fullWidth label="Dataset Name" variant="filled" />

                    <FormTextField
                      name="description"
                      fullWidth
                      label="Dataset Description"
                      variant="filled"
                      multiline
                      rows={2}
                    />
                  </Stack>
                </FormSection>

                {selectedProject.text_questions && Object.keys(selectedProject.text_questions).length > 0 && (
                  <FormSection title="Questions to Import">
                    <FormCheckboxGroup
                      name="selectedQuestions"
                      options={Object.entries(selectedProject.text_questions).map(([id, label]) => ({
                        id,
                        label,
                      }))}
                    />
                  </FormSection>
                )}

                <FormSection title="Additional Options">
                  <FormSelect name="textTargetColumn" label="Text Target Column" variant="filled" fullWidth>
                    <MenuItem value="original_text">original_text</MenuItem>
                    <MenuItem value="translated_text">translated_text</MenuItem>
                    <MenuItem value="text">text</MenuItem>
                  </FormSelect>
                  <FormCheckbox name="onlyComplete" label="Only Complete Conversations" />
                </FormSection>

                <FormSection title="Time Window">
                  <Stack spacing={2}>
                    <Stack spacing={2} direction="row">
                      <FormDateTimePicker name="startTime" label="Start Time" />
                      <FormDateTimePicker name="endTime" label="End Time" />
                      <Button
                        variant="text"
                        color="secondary"
                        title="Reset to Project Dates"
                        onClick={() => {
                          if (selectedProject) {
                            setValue("startTime", new Date(selectedProject.start_date));
                            setValue("endTime", new Date(selectedProject.end_date));
                          }
                        }}
                        sx={{ minWidth: "fit-content" }}
                      >
                        Reset
                      </Button>
                    </Stack>
                  </Stack>
                </FormSection>

                {Object.keys(formMethods.formState.errors).length > 0 && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {Object.entries(formMethods.formState.errors)
                      .map(([field, error]) => error.message)
                      .map((txt) => (
                        <Typography key={txt}>{txt}</Typography>
                      ))}
                  </Alert>
                )}

                <Stack direction="row" spacing={2} sx={{ alignSelf: "flex-end" }}>
                  <LoaderButton
                    variant="outlined"
                    color="primary"
                    title="Fetch Statistics"
                    disabled={fetchingStats || !selectedProject}
                    loading={fetchingStats}
                    onClick={handleFetchStatistics}
                  />

                  <LoaderButton
                    className="create-button"
                    variant="outlined"
                    color="secondary"
                    title="Create Dataset"
                    disabled={
                      !formMethods.formState.isValid ||
                      importing ||
                      !selectedProject ||
                      formMethods.formState.isSubmitting
                    }
                    loading={importing}
                    onClick={formMethods.handleSubmit(onSubmit)}
                  />
                </Stack>

                {statistics && (
                  <CollectStatisticsDialog
                    statistics={statistics}
                    project={selectedProject}
                    open={statisticsDialogOpen}
                    onClose={handleCloseStatistics}
                    startTime={formMethods.getValues("startTime")}
                    endTime={formMethods.getValues("endTime")}
                    onlyComplete={formMethods.getValues("onlyComplete")}
                  />
                )}
              </>
            )}
          </Stack>
        </Box>
      </FormProvider>
    </StandardCard>
  );
}
