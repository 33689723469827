import { ReactNode, createContext, useContext, useEffect } from "react";
import { useReducer } from "react";
import { ProjectModel } from "../api/apimodels";
import { ApiLoadingState } from "../api/types";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { Session } from "../api/sessionstore";
import { topLevelPageFromPath } from "../pages/PageInfo";

interface TokenPayload extends JwtPayload {
  org: number;
  org_name: string;
  org_role: number;
}

type OrganizationState = {
  id: number | null;
  role: number;
  name: string | undefined;
  loadingState: ApiLoadingState;
  projects: ProjectModel[];
};

export enum OrganizationActionTypes {
  setLoading,
  setProjects,
  loadingComplete,
  setOrganization,
}

interface OrganizationAction {
  type: OrganizationActionTypes;
}

export interface LoadingAction extends OrganizationAction {
  type: OrganizationActionTypes.setLoading;
  loadingState: ApiLoadingState;
}

export interface LoadingCompleteAction extends OrganizationAction {
  type: OrganizationActionTypes.loadingComplete;
  projects: ProjectModel[];
}

export interface SetProjectsAction extends OrganizationAction {
  type: OrganizationActionTypes.setProjects;
  projects: ProjectModel[];
}

export interface SetOrganizationAction extends OrganizationAction {
  type: OrganizationActionTypes.setOrganization;
  id: number;
  name: string;
  role: number;
}

type AnyOrganizationAction = LoadingAction | SetProjectsAction | LoadingCompleteAction | SetOrganizationAction;

const emptyOrganizationState: OrganizationState = {
  id: -1,
  name: "",
  role: 0,
  loadingState: ApiLoadingState.notLoading,
  projects: [],
};

function reducer(state: OrganizationState, action: AnyOrganizationAction) {
  switch (action.type) {
    case OrganizationActionTypes.setLoading:
      return {
        ...state,
        loadingState: action.loadingState,
      };
    case OrganizationActionTypes.setProjects:
      return {
        ...state,
        projects: action.projects,
      };
    case OrganizationActionTypes.loadingComplete:
      return {
        ...state,
        loadingState: ApiLoadingState.notLoading,
        projects: action.projects,
      };
    case OrganizationActionTypes.setOrganization:
      return {
        ...state,
        id: action.id,
        name: action.name,
        role: action.role,
      };
  }
  throw new Error("Unknown action.");
}

export const OrganizationContext = createContext(emptyOrganizationState);
export const OrganizationDispatcherContext = createContext((_: AnyOrganizationAction) => {});

export function useOrganizationReducer() {
  return useReducer(reducer, emptyOrganizationState);
}

export function useOrganization() {
  return useContext(OrganizationContext);
}

export function useOrganizationDispatch() {
  return useContext(OrganizationDispatcherContext);
}

function getOrganizationFromToken(token: string): { id: number; name: string; role: number } {
  const tokenData: TokenPayload = jwtDecode(token);
  return { id: tokenData["org"], name: tokenData["org_name"], role: tokenData["org_role"] };
}

export function isPathAvailable(path: string, token: string | undefined | null) {
  const { role } = token ? getOrganizationFromToken(token) : { role: 0 };
  const page = topLevelPageFromPath(path);
  return page ? page.role <= role : true;
}

export const OrganizationContextProvider = ({ children }: { children: ReactNode }) => {
  const [organization, organizationDispatch] = useOrganizationReducer();
  const token = Session.shared().token;
  useEffect(() => {
    if (token) {
      organizationDispatch({
        type: OrganizationActionTypes.setOrganization,
        ...getOrganizationFromToken(token),
      });
    }
  }, [token, organizationDispatch]);
  return (
    <OrganizationContext.Provider value={organization}>
      <OrganizationDispatcherContext.Provider value={organizationDispatch}>
        {children}
      </OrganizationDispatcherContext.Provider>
    </OrganizationContext.Provider>
  );
};
