import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { useFormContext, FieldValues, Path } from "react-hook-form";
import { Controller } from "react-hook-form";
type FormTextFieldProps<T extends FieldValues> = Omit<TextFieldProps, "name"> & {
  name: Path<T>;
};

export function FormTextField<T extends FieldValues>({ name, ...props }: FormTextFieldProps<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField {...field} {...props} error={!!fieldState.error} helperText={fieldState.error?.message} />
      )}
    />
  );
}
