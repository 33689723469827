export interface ISession {
  get token(): string | null;
  set token(t: string | null);
  get refreshToken(): string | null;
  set refreshToken(t: string | null);
}

enum SessionKeys {
  token = "session.token",
  refreshToken = "session.refreshToken",
}

enum SessionMetaKeys {
  storage = "session.meta.storage",
}

interface SessionStorageProvider {
  storage: Storage;
  name: string;
}

export const PersistentStorageProvider: SessionStorageProvider = Object.freeze({
  storage: localStorage,
  name: "persistent",
});

export const EphemeralStorageProvider: SessionStorageProvider = Object.freeze({
  storage: sessionStorage,
  name: "ephemeral",
});

const AllSessionKeys = [SessionKeys.refreshToken];

export class Session implements ISession {
  private refreshTokenStorage: Storage;
  private provider: SessionStorageProvider;

  constructor(provider: SessionStorageProvider) {
    this.refreshTokenStorage = provider.storage;
    this.provider = provider;
  }

  get token(): string | null {
    return sessionStorage.getItem(SessionKeys.token);
  }

  set token(t: string | null) {
    if (t === null) {
      sessionStorage.removeItem(SessionKeys.token);
    } else {
      sessionStorage.setItem(SessionKeys.token, t);
    }
  }

  get refreshToken(): string | null {
    return this.refreshTokenStorage.getItem(SessionKeys.refreshToken);
  }

  set refreshToken(t: string | null) {
    if (t === null) {
      this.refreshTokenStorage.removeItem(SessionKeys.refreshToken);
    } else {
      this.refreshTokenStorage.setItem(SessionKeys.refreshToken, t);
    }
  }

  private _changeStorageProvider(provider: SessionStorageProvider) {
    if (this.provider.name === provider.name) {
      return;
    }
    const refreshToken = this.refreshTokenStorage.getItem(SessionKeys.refreshToken);
    if (refreshToken) {
      provider.storage.setItem(SessionKeys.refreshToken, refreshToken);
    }
    this.clear();
    this.refreshTokenStorage = provider.storage;
    this.provider = provider;
  }

  clear() {
    sessionStorage.removeItem(SessionKeys.token);
    AllSessionKeys.forEach((k) => {
      this.refreshTokenStorage.removeItem(k);
    });
  }

  set(token: string, refreshToken: string) {
    this.token = token;
    this.refreshToken = refreshToken;
  }

  private static _sharedInstance: Session | null = null;

  public static createShared(provider: SessionStorageProvider) {
    this._sharedInstance = new Session(provider);
    localStorage.setItem(SessionMetaKeys.storage, provider.name);
  }

  public static storageProviderByName(name: string | undefined | null): SessionStorageProvider {
    switch (name) {
      case PersistentStorageProvider.name:
        return PersistentStorageProvider;
      case EphemeralStorageProvider.name:
        return EphemeralStorageProvider;
    }
    return EphemeralStorageProvider;
  }

  public static getDefaultStorageProvider(): SessionStorageProvider {
    return Session.storageProviderByName(localStorage.getItem(SessionMetaKeys.storage));
  }

  public static changeStorageProvider(instance: Session, name: string) {
    const provider = Session.storageProviderByName(name);
    instance._changeStorageProvider(provider);
    localStorage.setItem(SessionMetaKeys.storage, provider.name);
  }

  public static shared(): Session {
    return Session._sharedInstance!;
  }
}
