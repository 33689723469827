import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { OrganizationActionTypes, useOrganization, useOrganizationDispatch } from "../../state/organizationstate";
import { useApplication } from "../../state/applicationstate";
import { Apis } from "../../api/apis";
import { Session } from "../../api/sessionstore";
import { ArrowDropDown } from "@mui/icons-material";
import AdminIcon from "@mui/icons-material/AdminPanelSettings";
import { ApiRole } from "../../api/apimodels";

export function OrganizationSelector() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const organization = useOrganization();
  const organizationDispatch = useOrganizationDispatch();
  const { organizations } = useApplication();
  const authApi = Apis.shared().auth;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (orgId: number) => {
    const org = organizations.find((org) => org.id === orgId);
    if (Session.shared().token) {
      authApi.tokenRefresh(org!.id, Session.shared().refreshToken!).then(({ token, refreshToken }) => {
        Session.shared().set(token, refreshToken);
        organizationDispatch({
          type: OrganizationActionTypes.setOrganization,
          id: org!.id,
          name: org!.name,
          role: org!.role,
        });
        window.location.href = "/";
        handleClose();
      });
    }
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<ArrowDropDown />}
        sx={{ textTransform: "none" }}
        startIcon={organization.role >= ApiRole.admin && <AdminIcon fontSize="small" />}
      >
        {organization.name}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {(organizations ?? []).map((org) => (
          <MenuItem key={org.id} onClick={() => handleSelect(org.id)} selected={org.id === organization.id}>
            {org.role >= ApiRole.admin && (
              <ListItemIcon sx={{ "&.MuiListItemIcon-root": { minWidth: "28px" } }}>
                <AdminIcon fontSize="small" />
              </ListItemIcon>
            )}
            {org.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
