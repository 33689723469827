import { ApiId, JobInfoModel, StandardItemModel } from "../api/apimodels";

function timeAgo<T extends StandardItemModel>(item: T): number {
  return (Date.now() - new Date(item.created_at!).getTime()) / 1000;
}
/**
 * Creates a function that can be used to monitor a list of job ids.
 *
 * Each time the function is called the job ids are added to the monitor.
 *
 * At regular intervals (timeout) the function fetches the job statuses.
 *
 * If there are any completed jobs, the completed function is called and the job ids are removed from the monitor.
 *
 *
 * @param fetchFn A function that fetches a list of job items (to get the statuses)
 * @param completedFn A function that is called when there is one or more completed jobs
 * @param timeout The timeout for the monitor
 * @param jobTimeout The maximum time a job will be monitored
 * @returns A function that can be called with a list of job ids to add to the monitor
 */

export function createJobIdMonitor<T extends JobInfoModel>(
  fetchFn: (ids: ApiId[]) => Promise<T[]>,
  completedFn: (completed: ApiId[]) => void,
  timeout: number,
  jobTimeout: number = 60 * 60
) {
  const monitorSet = new Set<ApiId>();
  let handle: any = null;

  return (jobIds: ApiId[] | null) => {
    clearTimeout(handle);
    handle = null;
    if (jobIds === null || jobIds.length === 0) {
      return;
    }
    jobIds.forEach((i) => {
      monitorSet.add(i);
    });

    // this check is pointless because the timeout is always null at this point
    if (handle === null) {
      const timeoutFn = () => {
        fetchFn(Array.from(jobIds))
          .catch((error) => {
            console.error(error);
            return [];
          })
          .then((pending: T[]) => {
            const pendingIds = new Set(
              pending.filter((j) => j.status?.state !== "error" && timeAgo(j) < jobTimeout).map((c) => c.id!)
            );
            const completedIds = monitorSet.difference(pendingIds);
            if (completedIds.size > 0) {
              completedFn(Array.from(completedIds));
            }
            //Array.from(monitorSet).forEach((i) => { monitorSet.delete(i); });
            Array.from(completedIds).forEach((i) => {
              monitorSet.delete(i);
            });
            //Array.from(pendingIds).forEach((i) => { monitorSet.add(i); });
            if (monitorSet.size > 0) {
              handle = setTimeout(timeoutFn, timeout);
            } else {
              handle = null;
            }
          });
      };
      handle = setTimeout(timeoutFn, timeout);
    }
  };
}
