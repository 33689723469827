import { FetchStatus, NotAuthenticatedError } from "./types";
import { RequestInterceptor, ResponseInterceptor, ErrorInterceptor, RequestContext } from "./httpclient";
import { ISession } from "./sessionstore";
import { ApiError, ApiGeneralError, ApiNotAuthorizedError, ApiNotFoundError } from "./types";

export function createAuthRequestInterceptor(
  session: ISession,
  fetchStatusFn?: (status: FetchStatus) => void
): RequestInterceptor {
  if (!session.refreshToken) {
    return () => {
      throw new NotAuthenticatedError("Not authorized", window.location.href);
    };
  }

  return async (config: RequestInit): Promise<RequestInit> => {
    fetchStatusFn?.(FetchStatus.fetching);
    // Add token to request headers
    const newConfig = { ...config };
    const headers: Record<string, any> = { ...newConfig.headers };
    headers["Authorization"] = `Bearer ${session.token}`;
    newConfig.headers = headers;

    return newConfig;
  };
}

export function createAuthResponseInterceptor(
  session: ISession,
  tokenRefreshFn: (session: ISession) => Promise<void>,
  fetchStatusFn?: (status: FetchStatus) => void,
): ResponseInterceptor {
  return async (context: RequestContext): Promise<Response> => {
    if (context.response.status !== 401 || !session.refreshToken) {
      fetchStatusFn?.(FetchStatus.idle);
      return context.response;
    }
    fetchStatusFn?.(FetchStatus.refreshing);
    try {
      await tokenRefreshFn(session);
      // Get all headers except Authorization
      const headers = Object.fromEntries(
        Array.from(context.request.headers.entries())
          .filter(([key]) => !key.toLowerCase().includes('authorization'))
      );

      // Create new request with refreshed token
      const requestConfig: RequestInit = {
        method: context.request.method,
        headers: {
          ...headers,
          Authorization: `Bearer ${session.token}`,
        }
      };
      const newResponse = await fetch(context.response.url, requestConfig);

      if (newResponse.status === 401) {
        throw new NotAuthenticatedError("Token refresh failed - not authorized", window.location.href, new ApiNotAuthorizedError(401, {message: newResponse.statusText}));
      }

      return newResponse;
    } finally {
      fetchStatusFn?.(FetchStatus.idle);
    }
  };
}

export function createApiErrorInterceptor(): ErrorInterceptor {
  return async (error: any) => {
    // If it's already an ApiError, just rethrow it
    if (error instanceof ApiError) {
      throw error;
    }

    // Handle fetch errors (network issues, etc)
    if (error instanceof Error && !(error instanceof ApiError)) {
      throw new ApiGeneralError(undefined, {
        message: error.message,
        type: 'network_error'
      });
    }

    // Handle Response objects with error status
    if (error instanceof Response) {
      let details: Record<string, any> = {};
      try {
        details = await error.json();
      } catch (e) {}
      
      switch (error.status) {
        case 401:
        case 403:
          throw new ApiNotAuthorizedError(error.status, details);
        case 404:
          throw new ApiNotFoundError(error.status, details);
        default:
          if (error.status >= 400) {
            throw new ApiGeneralError(error.status, details);
          }
          throw error;
      }
    }

    // Fallback
    throw error;
  };
}

export function createStatusErrorInterceptor(
  statusFn?: (status: FetchStatus) => void
): ErrorInterceptor {
  return async (error: any) => {
    try {
      throw error;
    } finally {
      statusFn?.(FetchStatus.idle);
    }
  };
}
