import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import MuiToolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { Stack, Tooltip, Zoom, useMediaQuery } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { ApiRole, OrganizationInfo, ProjectModel } from "../../api/apimodels";
import * as PageInfo from "../../pages/PageInfo";
import { filterAvailablePages } from "../../pages/PageInfo";
import { ApplicationActionTypes, useApplication, useApplicationDispatch } from "../../state/applicationstate";
// import { CanucciLogo } from "./icons/CanucciLogo";
import { UITheme } from "../theme";
import { useOrganization } from "../../state/organizationstate";
import { UserMenu } from "./UserMenu";

// TODO: Clean up this sloppy mess.

const drawerWidth = 180;

function getAvailablePagesSlugs(projectId: number | undefined): Set<string> {
  if (projectId) {
    let pages = new Set(PageInfo.TopLevelPages.map((p) => p.slug));
    return pages;
  }
  return new Set([PageInfo.OverviewPage.slug]);
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.appBar.main,
  backgroundImage: "none",
  width: "100%",
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    paddingLeft: 0,
  },
}));

type NavigationItemProps = {
  slug: string;
  icon: any;
  name: string;
  open: boolean;
  color?: string;
  disabled?: boolean;
  onMenuItemClick: (e: React.MouseEvent<HTMLElement>, slug: string) => void;
};

function NavigationItem(props: NavigationItemProps) {
  const { slug, icon, name, open, onMenuItemClick, disabled, color } = props;
  return (
    <Tooltip title={name} placement="right" TransitionComponent={Zoom}>
      <ListItem key={slug} disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={(e) => onMenuItemClick(e, slug)}
          disabled={disabled}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              color,
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={name} sx={{ opacity: open ? 1 : 0, color }} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
}

type Props = {
  children: React.ReactNode;
};

// Simplify navigation mapping
const NAVIGATION_PATHS: Record<string, (projectId?: number) => string> = {
  [PageInfo.OverviewPage.slug]: PageInfo.overviewPath,
  [PageInfo.DatasetPage.slug]: (projectId) => (projectId ? PageInfo.datasetPath(projectId, undefined) : ""),
  [PageInfo.ProcessorPage.slug]: (projectId) => (projectId ? PageInfo.processorPath(projectId, undefined) : ""),
  [PageInfo.ExplorationPage.slug]: (projectId) => (projectId ? PageInfo.explorationPath(projectId, undefined) : ""),
  [PageInfo.PromptPage.slug]: (projectId) => (projectId ? PageInfo.promptPath(projectId, undefined) : ""),
  [PageInfo.ProjectPage.slug]: (projectId) => (projectId ? PageInfo.projectPath(projectId) : ""),
};

function pageInfoFromPath(pathname: string | undefined): PageInfo.PageInfo | undefined {
  if (!pathname) return undefined;

  const lookup = Object.fromEntries(PageInfo.TopLevelPages.map((page) => [page.slug, page]));
  const slug = pathname
    .split(/[/?]/)
    .reverse()
    .find((segment) => lookup[segment]);
  return slug ? lookup[slug] : undefined;
}

function getAvailablePages(organization: OrganizationInfo) {
  if (organization.role >= ApiRole.admin) {
    return PageInfo.TopLevelPages;
  }
  return PageInfo.TopLevelPages.filter((page) => page.role <= organization.role);
}

export default function NavigationFramework(props: Props) {
  const organization = useOrganization();
  const params = useParams();
  const projectId = params.projectId ? parseInt(params.projectId) : undefined;
  const availablePagesSlugs = useMemo(() => getAvailablePagesSlugs(projectId), [projectId]);
  const navigate = useNavigate();
  const { maskTexts, uiTheme } = useApplication();
  const appDispatch = useApplicationDispatch();
  const location = useLocation();
  const activePage = pageInfoFromPath(location.pathname);
  const theme = useTheme();
  const selectedColor = theme.palette.primary.main;
  const projectMeta = (useRouteLoaderData("project") as ProjectModel) || undefined;
  const isNarrow = useMediaQuery(theme.breakpoints.between("xs", "lg"));
  const [open, setOpen] = useState(!isNarrow);

  useEffect(() => {
    setOpen(!isNarrow);
  }, [isNarrow]);

  const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    appDispatch({ type: ApplicationActionTypes.setTheme, theme: e.target.checked ? UITheme.dark : UITheme.light });
  };

  const handleMaskTextsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    appDispatch({ type: ApplicationActionTypes.setMaskTexts, isMasked: e.target.checked });
  };

  const onMenuItemClick = (e: React.MouseEvent<HTMLElement>, slug: string) => {
    e.stopPropagation();
    e.preventDefault();

    const pathGenerator = NAVIGATION_PATHS[slug];
    if (pathGenerator) {
      const path = pathGenerator(projectId);
      if (path) navigate(path);
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <AppBar position="fixed" open={open} sx={{ left: 0, width: `100%` }}>
        <Toolbar>
          <Stack
            sx={{
              backgroundColor: theme.palette.appBar.main,
              height: "100%",
              width: "100%",
              minHeight: "64px",
              marginLeft: { xs: "14px", sm: "16px" },
              alignItems: "center",
              p: 0,
              justifyContent: "flex-start",
            }}
            direction="row"
          >
            <Stack direction="row" sx={{ flexGrow: 1, pt: 0.33, overflow: "hidden" }}>
              <Box sx={{ alignContent: "center" }}>
                <Typography
                  variant="h6"
                  noWrap
                  sx={{ ml: 1, mr: 2, fontWeight: 600, display: "block", mt: "-1px" }}
                  component="span"
                >
                  {projectMeta ? projectMeta.name : "Overview"}
                </Typography>
              </Box>
              <Tooltip title={projectMeta ? projectMeta.subtitle : ""}>
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                  <Typography
                    variant="body1"
                    component="span"
                    display={{ xs: "none", sm: "inline" }}
                    sx={{
                      opacity: 0.75,
                      fontWeight: 100,
                      textOverflow: "ellipsis",
                      textWrap: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {projectMeta ? projectMeta.subtitle : ""}
                  </Typography>
                </Box>
              </Tooltip>
            </Stack>
            <Box justifySelf="flex-end">
              <UserMenu
                uiTheme={uiTheme}
                organizationName={organization.name}
                maskTexts={maskTexts}
                onThemeChange={handleThemeChange}
                onMaskTextsChange={handleMaskTextsChange}
              />
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{ background: theme.palette.appBar.main, boxShadow: "none", backgroundImage: "none", minHeight: "64px" }}
        />
        <List>
          {filterAvailablePages(PageInfo.OrganizationPages, organization.role as ApiRole)
            .map((p) => ({
              p,
              color: p.slug === activePage?.slug ? selectedColor : undefined,
            }))
            .map(({ p, color }) => (
              <NavigationItem
                key={p.slug}
                slug={p.slug}
                name={p.name}
                icon={p.menuIcon}
                color={color}
                onMenuItemClick={onMenuItemClick}
                open={open}
                disabled={!availablePagesSlugs.has(p.slug)}
              />
            ))}
        </List>
        <Divider />
        <List>
          {filterAvailablePages(PageInfo.ProjectPages, organization.role as ApiRole)
            .map((p) => ({
              p,
              color: p.slug === activePage?.slug ? selectedColor : undefined,
            }))
            .map(({ p, color }) => (
              <NavigationItem
                key={p.slug}
                slug={p.slug}
                name={p.name}
                icon={p.menuIcon}
                color={color}
                onMenuItemClick={onMenuItemClick}
                open={open}
                disabled={!availablePagesSlugs.has(p.slug)}
              />
            ))}
        </List>
      </Drawer>
      <Box sx={{ width: "100%", pt: 7 }}>{props.children}</Box>
    </Box>
  );
}
