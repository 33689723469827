export type RequestInterceptor = (config: RequestInit) => RequestInit | Promise<RequestInit>;

export interface RequestContext {
  requestId: number;
  request: Request;
  response: Response;
}

export type ResponseInterceptor = (context: RequestContext) => Promise<Response>;
export type ErrorInterceptor = (error: any) => any;

let globalRequestId = 0;

export class HttpClient {
  private _baseUrl: string;
  private requestInterceptors: RequestInterceptor[] = [];
  private responseInterceptors: ResponseInterceptor[] = [];
  private errorInterceptors: ErrorInterceptor[] = [];

  constructor(
    baseUrl: string,
    requestInterceptors: RequestInterceptor[] = [],
    responseInterceptors: ResponseInterceptor[] = [],
    errorInterceptors: ErrorInterceptor[] = []
  ) {
    this._baseUrl = baseUrl;
    this.requestInterceptors = requestInterceptors;
    this.responseInterceptors = responseInterceptors;
    this.errorInterceptors = errorInterceptors;
  }

  get baseUrl() {
    return this._baseUrl;
  }

  addRequestInterceptor(interceptor: RequestInterceptor) {
    this.requestInterceptors.push(interceptor);
  }

  addResponseInterceptor(interceptor: ResponseInterceptor) {
    this.responseInterceptors.push(interceptor);
  }

  addErrorInterceptor(interceptor: ErrorInterceptor) {
    this.errorInterceptors.push(interceptor);
  }

  private async applyRequestInterceptors(config: RequestInit): Promise<RequestInit> {
    let finalConfig = { ...config };
    for (const interceptor of this.requestInterceptors) {
      finalConfig = await interceptor(finalConfig);
    }
    return finalConfig;
  }

  private async applyResponseInterceptors(context: RequestContext): Promise<Response> {
    let finalResponse = context.response;
    for (const interceptor of this.responseInterceptors) {
      finalResponse = await interceptor({ ...context, response: finalResponse });
    }
    return finalResponse;
  }

  async fetch(path: string, init?: RequestInit): Promise<Response> {
    const url = new URL(path, this._baseUrl).toString();
    try {
      const config = await this.applyRequestInterceptors(init || {});
      const request = new Request(url, config);
      let response = await fetch(request);
      const requestId = globalRequestId++; 
      
      // For 401s, give auth response interceptor a chance to handle it
      if (response.status === 401) {
        response = await this.applyResponseInterceptors({ request, response, requestId });
      }
      
      // If still not ok after response interceptors, throw for error handling
      if (!response.ok) {
        throw response;
      }
      
      // For successful responses, still apply response interceptors
      return response.status === 401 ? response : await this.applyResponseInterceptors({ request, response, requestId });
    } catch (e) {
      let error = e;
      for (const interceptor of this.errorInterceptors) {
        error = await interceptor(error);
      }
      throw error;
    }
  }
}
