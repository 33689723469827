import OverviewIcon from "@mui/icons-material/Domain";
import ProjectIcon from "@mui/icons-material/Folder";
import DatasetsIcon from "@mui/icons-material/Dataset";
import ProcessorsIcon from "@mui/icons-material/Memory";
import ExplorationIcon from "@mui/icons-material/Search";
import PromptIcon from "@mui/icons-material/Notes";
import { ApiId, ApiRole } from "../api/apimodels";

export enum PageType {
  organization,
  project,
}

export type PageInfo = {
  slug: string;
  name: string;
  menuName: string;
  paramId: string | undefined;
  type: PageType;
  menuIcon: any;
  role: number;
};

export const OverviewPage: PageInfo = {
  name: "All projects",
  menuName: "All projects",
  slug: "overview",
  paramId: undefined,
  type: PageType.organization,
  menuIcon: <OverviewIcon />,
  role: 0,
};

export const ProjectPage: PageInfo = {
  name: "Project",
  menuName: "Project",
  slug: "project",
  paramId: "projectId",
  type: PageType.project,
  menuIcon: <ProjectIcon />,
  role: 0,
};

export const DatasetPage: PageInfo = {
  name: "Dataset",
  menuName: "Dataset",
  slug: "dataset",
  paramId: "datasetId",
  type: PageType.project,
  menuIcon: <DatasetsIcon />,
  role: ApiRole.admin,
};

export const ProcessorPage: PageInfo = {
  name: "Model",
  menuName: "Model",
  slug: "processor",
  paramId: "processorId",
  type: PageType.project,
  menuIcon: <ProcessorsIcon />,
  role: ApiRole.admin,
};

export const ExplorationPage: PageInfo = {
  name: "Exploration",
  menuName: "Exploration",
  slug: "exploration",
  paramId: "explorationId",
  type: PageType.project,
  menuIcon: <ExplorationIcon />,
  role: ApiRole.standard,
};

export const PromptPage: PageInfo = {
  name: "Prompt",
  menuName: "Prompt",
  slug: "prompt",
  paramId: "promptId",
  type: PageType.project,
  menuIcon: <PromptIcon />,
  role: ApiRole.standard,
};

export const TopLevelPages = [OverviewPage, ProjectPage, ExplorationPage, DatasetPage, ProcessorPage, PromptPage];

export const OrganizationPages = [OverviewPage];

export const ProjectPages = [ProjectPage, ExplorationPage, DatasetPage, ProcessorPage, PromptPage];

export function filterAvailablePages(pages: PageInfo[], role: ApiRole) {
  return pages.filter((page) => page.role <= role);
}

export function overviewPath() {
  return "/" + OverviewPage.slug;
}

export function projectPath(id: ApiId | string) {
  const pieces = [ProjectPage.slug, id];
  return pieces.join("/");
}

export function datasetPath(projectId: ApiId | string, datasetId?: ApiId | string, paramPrefix = "") {
  const pieces = [ProjectPage.slug, paramPrefix + projectId, DatasetPage.slug, paramPrefix + datasetId];
  if (!datasetId) {
    pieces.pop();
  }
  return pieces.join("/");
}

export function processorPath(projectId: ApiId | string, processorId?: ApiId | string, paramPrefix = "") {
  const pieces = [ProjectPage.slug, paramPrefix + projectId, ProcessorPage.slug, paramPrefix + processorId];
  if (!processorId) {
    pieces.pop();
  }
  return pieces.join("/");
}

export function explorationPath(projectId: ApiId | string, explorationId?: ApiId | string, paramPrefix = "") {
  const pieces = [ProjectPage.slug, paramPrefix + projectId, ExplorationPage.slug, paramPrefix + explorationId];
  if (!explorationId) {
    pieces.pop();
  }
  return pieces.join("/");
}

export function promptPath(projectId: ApiId | string, promptId?: ApiId | string, paramPrefix = "") {
  const pieces = [ProjectPage.slug, paramPrefix + projectId, PromptPage.slug, paramPrefix + promptId];
  if (!promptId) {
    pieces.pop();
  }
  return pieces.join("/");
}

export function topLevelPageFromPath(path: string): PageInfo | undefined {
  const pieces = path.split("/");
  if (pieces.length === 0) {
    return OverviewPage;
  }
  while (pieces.length > 0 && pieces[0] === "") {
    pieces.shift();
  }
  if (pieces.length === 0) {
    return OverviewPage;
  }
  if (pieces[0] === ProjectPage.slug) {
    if (pieces.length > 2) {
      return TopLevelPages.find((p) => p.slug === pieces[2]) ?? ProjectPage;
    }
  }
  return TopLevelPages.find((p) => p.slug === pieces[0]);
}
