import { Box, Container, Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useLoaderData, useLocation, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { ApiId, DatasetModel, ExplorationModel, ProjectModel } from "../../api/apimodels";
import { Apis } from "../../api/apis";
import { ColumnId } from "../../api/dataconstants";
import { ApiBatchResult } from "../../api/types";
import ResourceCreateForm from "../../components/common/ResourceCreateForm";
import ResourceList from "../../components/common/ResourceList";
import ResourceSelect from "../../components/common/ResourceSelect";
import { sortedResource } from "../../util/sorting";
import { explorationPath, ExplorationPage as ExplorationsPageInfo } from "../PageInfo";
import { inferDefaultName } from "../../util/string";

function validVersions(items: DatasetModel[], requiredColumns: string[]): DatasetModel[] {
  const rCols = new Set(requiredColumns);
  return items.filter((d) => {
    const iCols = new Set(d.columns ?? []);
    return rCols.difference(iCols).size === 0;
  });
}

export const requiredColumns: string[] = [ColumnId.TEXT, ColumnId.TEXT_ID];

export function CreateExplorationsForm({ preselectedDatasetIds }: { preselectedDatasetIds?: [ApiId, ApiId] }) {
  const api = Apis.shared().project;
  const params = useParams();
  const navigate = useNavigate();
  const projectMeta = useRouteLoaderData("project") as ProjectModel;
  const [datasets, setDatasets] = useState<DatasetModel[]>([]);
  const datasetsSorted = sortedResource(datasets ?? [], true);
  const hasDatasets = datasetsSorted.length > 0;
  const projectId: number | undefined = params.projectId ? parseInt(params.projectId) : undefined;
  const [loading, setLoading] = useState(false);
  const [dataset, setDataset] = useState<DatasetModel | undefined>();
  const [version, setDatasetVersion] = useState<DatasetModel | undefined>();
  const [versions, setDatasetVersions] = useState<DatasetModel[]>([]);
  const [defaultName, setDefaultName] = useState("");
  const [defaultDescription, setDefaultDescription] = useState("");
  const [error, setError] = useState<Error | undefined>();

  const onCreate = async (name: string, description: string) => {
    const dataset_id = version!.id!;
    const exploration: ExplorationModel = { dataset_id, name, description, selections: [] };
    setError(undefined);
    setLoading(true);
    if (projectId) {
      try {
        const created = await api.createExploration(projectId, exploration);
        setLoading(false);
        navigate("/" + explorationPath(projectId!, created!.id));
      } catch (e) {
        setLoading(false);
        setError(e as Error);
        console.error("Upload of model failed, statusCode", e);
      }
    }
  };

  const canCreate = () => {
    return !!dataset && !!projectId && !!version;
  };

  function getDefaultName(dataset: DatasetModel, version?: DatasetModel) {
    const shortOriginalName = inferDefaultName(dataset.name, 16);
    const shortVersionName = version ? inferDefaultName(version.name, 16) : "";
    console.log(version);
    return [shortOriginalName, shortVersionName].join(" | ");
  }

  function getDefaultDescription(dataset: DatasetModel, version?: DatasetModel) {
    return `${dataset.name}` + (version ? `, ${version!.name}` : "");
  }

  const onChangeDataset = useCallback(
    (d: DatasetModel, versionId?: ApiId) => {
      setLoading(true);
      setDataset(d);
      setDatasetVersion(undefined);
      setDefaultName(getDefaultName(d, version));
      setDefaultDescription(getDefaultDescription(d, version));
      Apis.shared()
        .project.fetchDatasets(projectMeta.id!, d.id!)
        .then((result: ApiBatchResult<DatasetModel>) => {
          setLoading(false);
          const sortedVersions = validVersions(sortedResource(result.items, true), requiredColumns);
          setDatasetVersions(sortedVersions);
          if (versionId) {
            const selectedVersion = sortedVersions.find((v) => v.id === versionId);
            if (selectedVersion) {
              setDefaultName(getDefaultName(d, selectedVersion));
              setDefaultDescription(getDefaultDescription(d, selectedVersion));
              setDatasetVersion(selectedVersion);
            }
          }
        })
        .catch((error) => {
          setError(error);
        });
    },
    [projectMeta.id, version],
  );

  function onChangeDatasetVersion(v: DatasetModel) {
    setDefaultName(getDefaultName(dataset!, v));
    setDefaultDescription(getDefaultDescription(dataset!, v));
    setDatasetVersion(v);
  }

  useEffect(() => {
    if (preselectedDatasetIds && hasDatasets) {
      const [parentId, versionId] = preselectedDatasetIds;
      const parentDataset = datasetsSorted.find((d) => d.id === parentId);
      if (parentDataset) {
        onChangeDataset(parentDataset, versionId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedDatasetIds, hasDatasets]);

  useEffect(() => {
    setLoading(true);
    api
      .fetchDatasets(projectMeta.id!)
      .then((result: ApiBatchResult<DatasetModel>) => {
        setDatasets(result.items);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [projectMeta.id, api]);

  return (
    <ResourceCreateForm
      title="Add exploration"
      icon={ExplorationsPageInfo.menuIcon}
      embedded={false}
      error={error}
      onCreate={onCreate}
      canCreate={canCreate}
      defaultName={defaultName}
      defaultDescription={defaultDescription}
      resourceNameLabel={"Exploration name"}
      resourceDescriptionLabel={"Exploration description"}
      createButtonLabel={"Create exploration"}
    >
      <ResourceSelect
        className="resource-select"
        label={loading ? "Datasets loading..." : "Dataset"}
        disabled={loading}
        selected={dataset as DatasetModel}
        resourceFilter={(r) => !!(r as DatasetModel).filename}
        onChange={(d) => onChangeDataset(d)}
        resources={datasetsSorted}
        sx={{ mb: 1 }}
      />
      {versions.length > 0 && (
        <ResourceSelect
          className="resource-select"
          label={loading ? "Versions loading..." : "Version"}
          disabled={loading}
          selected={version as DatasetModel}
          resourceFilter={(r) => !!(r as DatasetModel).filename}
          onChange={(d) => onChangeDatasetVersion(d)}
          renderName={(d: DatasetModel) => (!d.parent_id ? `(Original) ${d.name}` : d.name)}
          resources={versions}
          sx={{ mb: 1 }}
        />
      )}
    </ResourceCreateForm>
  );
}

export default function ExplorationPage() {
  const data = useLoaderData() as ApiBatchResult<ExplorationModel>;
  const navigate = useNavigate();
  const items = sortedResource(data.items, true);
  const location = useLocation();
  const [preselectedDatasetIds, setPreselectedDatasetIds] = useState<[ApiId, ApiId] | undefined>();

  function onSelectExploration(id: number) {
    navigate("./" + id);
  }

  useEffect(() => {
    const { state } = location;
    if (state && state.selectedDatasets) {
      setPreselectedDatasetIds(state.selectedDatasets);
    }
  }, [location]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {!data.empty && (
              <Grid item xs={12} md={8} lg={8}>
                <ResourceList
                  onSelectResource={onSelectExploration}
                  title="Explorations"
                  icon={ExplorationsPageInfo.menuIcon}
                  items={items}
                  createLabel="Add exploration"
                  isLoadingFn={(item) => false}
                  forceExpand={data.count === 0}
                />
              </Grid>
            )}
            <Grid item xs={12} md={4} lg={4}>
              <CreateExplorationsForm preselectedDatasetIds={preselectedDatasetIds} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
