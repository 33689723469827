import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TransitionProps } from "@mui/material/transitions";
import { FC, forwardRef, useEffect, useCallback, useState } from "react";
import {
  ApiId,
  JobExecution,
  JobExecutionIdentifier,
  JobExecutionStatusHistory,
  LogEntryModel,
} from "../../api/apimodels";
import moment from "moment";
import { getJobStatusLabel } from "../../util/jobstate";
import { Apis } from "../../api/apis";
import { TableVirtuoso, TableComponents } from "react-virtuoso";
import React from "react";
// Severity colors mapping
const severityColors: Record<string, string> = {
  DEFAULT: "#666666",
  DEBUG: "#6C757D",
  INFO: "#0D6EFD",
  NOTICE: "#0DCAF0",
  WARNING: "#FFC107",
  ERROR: "#DC3545",
  CRITICAL: "#BD2130",
  ALERT: "#A71D2A",
  EMERGENCY: "#710C04",
  NONE: "#666666",
};

const StyledLogTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  fontSize: "0.75rem",
  fontFamily: "monospace",
}));

const StyledTableCell = styled(StyledLogTableCell)(({ theme }) => ({
  whiteSpace: "pre-wrap",
  maxWidth: "50vw",
}));

const SeverityCell = styled(StyledLogTableCell)<{ severity: string }>(({ theme, severity }) => ({
  color: severityColors[severity] || severityColors.DEFAULT,
  fontWeight: 600,
}));

const FullscreenDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    background: theme.palette.modalDark,
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type LogsModalProps = {
  open: boolean;
  execution: JobExecution;
  jobId: ApiId;
  projectId: ApiId;
  onClose: () => void;
};

const formatMachineConfig = (config: { memory: string; cpu: string; timeout: number }) => {
  return `Memory: ${config.memory}, CPU: ${config.cpu}, Timeout: ${config.timeout}s`;
};

const StatusHistory: FC<{ statusHistory: JobExecutionStatusHistory[] }> = ({ statusHistory }) => {
  const sortedHistory = [...statusHistory].sort(
    (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  );

  const calculateDuration = (current: string, next?: string) => {
    if (!next) return "";
    const duration = moment(next).diff(moment(current), "seconds");
    return `${duration}s`;
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Timestamp</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Message</TableCell>
          <TableCell>Duration</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedHistory.map((status, index) => (
          <TableRow key={status.created_at}>
            <TableCell>{moment(status.created_at).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
            <TableCell>{getJobStatusLabel(status.status)}</TableCell>
            <TableCell>{status.message || "-"}</TableCell>
            <TableCell>
              {calculateDuration(
                status.created_at,
                index < sortedHistory.length - 1 ? sortedHistory[index + 1].created_at : undefined,
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const ExecutionDetails: FC<{ data: JobExecutionIdentifier; statusHistory: JobExecutionStatusHistory[] }> = ({
  data,
  statusHistory,
}) => {
  let jobData: Record<string, any> | null = null;
  if (data.job_data) {
    try {
      jobData = JSON.parse(data.job_data);
    } catch (e) {
      console.error("Failed to parse job_data:", e);
    }
  }

  return (
    <Box>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" sx={{ fontWeight: 600, width: "200px" }}>
              Image
            </TableCell>
            <TableCell
              sx={{
                whiteSpace: "nowrap",
                overflow: "auto",
                maxWidth: "600px",
              }}
            >
              {data.image_uri}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" sx={{ fontWeight: 600 }}>
              Environment
            </TableCell>
            <TableCell sx={{ whiteSpace: "nowrap" }}>{data.environment}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" sx={{ fontWeight: 600 }}>
              Hardware
            </TableCell>
            <TableCell sx={{ whiteSpace: "nowrap" }}>{formatMachineConfig(data.hardware_config)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" sx={{ fontWeight: 600 }}>
              ID
            </TableCell>
            <TableCell
              sx={{
                fontFamily: "monospace",
                fontSize: "0.875rem",
                whiteSpace: "nowrap",
              }}
            >
              {data.id}
            </TableCell>
          </TableRow>
          {data.env_vars && Object.keys(data.env_vars).length > 0 && (
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: 600 }}>
                Environment Variables
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    overflow: "auto",
                    maxWidth: "600px",
                  }}
                >
                  <pre style={{ margin: 0, whiteSpace: "pre" }}>{JSON.stringify(data.env_vars, null, 2)}</pre>
                </Box>
              </TableCell>
            </TableRow>
          )}
          {jobData && (
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: 600 }}>
                Job Data
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    overflow: "auto",
                    maxWidth: "600px",
                  }}
                >
                  <pre style={{ margin: 0, whiteSpace: "pre", fontSize: "0.875rem" }}>
                    {JSON.stringify(jobData, null, 2)}
                  </pre>
                </Box>
              </TableCell>
            </TableRow>
          )}
          {data.details && (
            <TableRow>
              <TableCell component="th" sx={{ fontWeight: 600 }}>
                Additional Details
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    overflow: "auto",
                    maxWidth: "600px",
                  }}
                >
                  <pre style={{ margin: 0, whiteSpace: "pre" }}>{JSON.stringify(data.details, null, 2)}</pre>
                </Box>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell component="th" sx={{ fontWeight: 600 }}>
              Status
            </TableCell>
            <TableCell>
              <StatusHistory statusHistory={statusHistory} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

const VirtuosoTableComponents: TableComponents<LogEntryModel> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
  Table: (props) => <Table {...props} sx={{ borderCollapse: "separate", tableLayout: "fixed" }} />,
  // TableHead: TableHead as React.ComponentType<TableHeadProps>,
  TableRow,
  // TableBody,
};

export const LogsModal: FC<LogsModalProps> = ({ open, execution, jobId, projectId, onClose }) => {
  const [selectedExtra, setSelectedExtra] = useState<Record<string, any> | null>(null);
  const [showExecution, setShowExecution] = useState(false);
  const [logs, setLogs] = useState<LogEntryModel[] | "loading">([]);
  const virtuosoRef = React.useRef<any>(null);

  const formatTimestamp = (timestamp: string) => {
    return new Date(timestamp).toLocaleString();
  };

  const handleOpenExtra = (extra: Record<string, any>) => {
    setSelectedExtra(extra);
  };

  const handleCloseExtra = () => {
    setSelectedExtra(null);
  };

  const fetchLogs = useCallback(() => {
    setLogs("loading");
    Apis.shared()
      .project.fetchJobLogs(projectId, jobId)
      .then((newLogs) => {
        setLogs(newLogs);
        // Scroll to bottom after logs are loaded
        setTimeout(() => {
          virtuosoRef.current?.scrollToIndex(newLogs.length - 1);
        }, 0);
      })
      .catch(() => setLogs([]));
  }, [projectId, jobId]);

  useEffect(() => {
    if (open) {
      fetchLogs();
    }
  }, [open, fetchLogs]);

  const fixedHeaderContent = () => (
    <TableRow sx={{ backgroundColor: "background.default" }}>
      <TableCell width="175px">Timestamp</TableCell>
      <TableCell width="100px">Severity</TableCell>
      <TableCell>Message</TableCell>
      <TableCell width="80px"></TableCell>
    </TableRow>
  );

  const rowContent = (_index: number, log: LogEntryModel) => (
    <React.Fragment>
      <StyledTableCell>{formatTimestamp(log.timestamp)}</StyledTableCell>
      <SeverityCell severity={log.severity}>{log.severity}</SeverityCell>
      <StyledTableCell>{log.message}</StyledTableCell>
      <StyledTableCell>
        {Object.keys(log.extra).length > 0 && (
          <Button
            size="small"
            variant="text"
            sx={{ fontSize: "0.75rem", p: 0, m: 0 }}
            onClick={() => handleOpenExtra(log)}
          >
            <ArrowForwardIcon fontSize="small" />
          </Button>
        )}
      </StyledTableCell>
    </React.Fragment>
  );

  return (
    <FullscreenDialog open={open} onClose={onClose} fullScreen TransitionComponent={Transition}>
      <AppBar sx={{ position: "sticky" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            Logs
          </Typography>
          <Button color="inherit" onClick={fetchLogs} disabled={logs === "loading"}>
            {logs === "loading" ? <CircularProgress size={24} /> : "Fetch logs"}
          </Button>
          {execution.execution_identifier && (
            <Button color="inherit" onClick={() => setShowExecution(true)}>
              Execution Details
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Paper sx={{ height: "calc(100vh - 64px)", width: "100%" }}>
        {logs !== "loading" ? (
          <TableVirtuoso
            ref={virtuosoRef}
            data={logs}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
          />
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <CircularProgress />
          </Box>
        )}
      </Paper>

      <Dialog open={!!selectedExtra} onClose={handleCloseExtra} maxWidth="md" fullWidth>
        <DialogTitle>
          Extra Details
          <IconButton onClick={handleCloseExtra} sx={{ position: "absolute", right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <pre style={{ margin: 0, whiteSpace: "pre-wrap" }}>
            {selectedExtra && JSON.stringify(selectedExtra, null, 2)}
          </pre>
        </DialogContent>
      </Dialog>

      <Dialog open={showExecution} onClose={() => setShowExecution(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          Execution Details
          <IconButton onClick={() => setShowExecution(false)} sx={{ position: "absolute", right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {execution.execution_identifier && (
            <ExecutionDetails data={execution.execution_identifier} statusHistory={execution.status_history} />
          )}
        </DialogContent>
      </Dialog>
    </FullscreenDialog>
  );
};
