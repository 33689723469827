import { boolStr } from "../../util/params";
import { ApiId, ProjectModel } from "../apimodels";
import { standardDeleteOptions, standardGetOptions, standardPostOptions, standardPutOptions } from "../helpers";
import { HttpClient } from "../httpclient";
import { FetchFn, IMetadataApi } from "../types";

export class MetadataApi implements IMetadataApi {
  private baseUrl: string;
  private fetchFn: FetchFn;
  public ver: number;

  constructor(httpClient: HttpClient) {
    this.baseUrl = httpClient.baseUrl;
    this.fetchFn = (input, init) => httpClient.fetch(input, init);
    this.ver = Math.random() * 10000000;
  }

  // TODO: Change to BatchResult
  async fetchProjects(): Promise<ProjectModel[]> {
    const url = `${this.baseUrl}/project`;
    const response = await this.fetchFn(url, standardGetOptions());
    const data = await response.json();
    return data as ProjectModel[];
  }

  async createProject(data: ProjectModel, defaultContent?: boolean): Promise<ProjectModel> {
    const url = `${this.baseUrl}/project?add_default_content=${boolStr(defaultContent)}`;
    const options = standardPostOptions({ body: JSON.stringify(data) });
    const response = await this.fetchFn(url, options);
    return await response.json() as ProjectModel;
  }

  async updateProject(projectId: ApiId, name?: string, subtitle?: string, description?: string): Promise<ProjectModel> {
    const url = `${this.baseUrl}/project/${projectId}`;
    const options = standardPutOptions({ body: JSON.stringify({ name, subtitle, description }) });
    const response = await this.fetchFn(url, options);
    const data = await response.json();
    return data as ProjectModel;
  }

  async deleteProject(projectId: ApiId): Promise<void> {
    const url = `${this.baseUrl}/project/${projectId}`;
    const options = standardDeleteOptions();
    await this.fetchFn(url, options);
    return;
  }
}
