export interface UrlSegmentExtractor {
  segment: string;
  transform?: (value: string) => any;
}

export function extractFromUrlPath(url: string, config: UrlSegmentExtractor): any | undefined {
  const segments = new URL(url).pathname.split("/");
  const index = segments.findLastIndex((segment) => segment === config.segment);
  if (index === -1 || index === segments.length - 1) return undefined;
  const value = segments[index + 1];
  return config.transform ? config.transform(value) : value;
}
