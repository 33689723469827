import { useEffect } from "react";
import { useNavigate, useRouteError, useLocation, Link as RouterLink } from "react-router-dom";
import { NotAuthenticatedError } from "../api/types";
import { Avatar, Box, Container, Link, Paper, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import { ApplicationThemeProvider } from "../components/theme";
import { useApplication } from "../state/applicationstate";

function ErrorPageContent() {
  const error = useRouteError() as any;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (error?.status === 401 || error?.statusCode === 401 || error instanceof NotAuthenticatedError) {
      const currentPath = location.pathname;
      // Don't redirect to login if we're already there
      const redirectUrl =
        currentPath === "/login"
          ? location.state?.redirectUrl || "/" // Preserve existing redirect URL or default to root
          : currentPath;
      navigate("/login", {
        state: { redirectUrl },
        replace: true,
      });
    }
  }, [error, navigate, location]);

  // Get error message from different error types
  const errorMessage = error instanceof Error ? error.message : error?.statusText || error?.message || "Unknown error";

  return (
    <Paper sx={{ height: "100%" }}>
      <Box
        sx={{ display: "flex", direction: "column", justifyContent: "center", alignItems: "center", height: "100%" }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Container maxWidth="sm">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, color: "error.main", bgcolor: "transparent" }}>
                <ErrorIcon sx={{ fontSize: 48 }} />
              </Avatar>
              <Typography component="h1" variant="h5" sx={{ my: 2 }}>
                Oops!
              </Typography>
              <Typography component="p" variant="body1" sx={{ mb: 2, textAlign: "center" }}>
                The following error occurred while loading this page:
                <br />
                <Typography component="span" variant="body1" sx={{ mb: 2 }} color="error.main">
                  {errorMessage || "Unknown error"}
                </Typography>
              </Typography>
              <Typography component="h4" variant="body1" sx={{ mb: 2, mt: 2 }}>
                Please try to{" "}
                <Link to="/login" component={RouterLink}>
                  login again
                </Link>
                .
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </Paper>
  );
}

export default function ErrorPage() {
  const { uiTheme } = useApplication();
  return (
    <ApplicationThemeProvider uiTheme={uiTheme}>
      <ErrorPageContent />
    </ApplicationThemeProvider>
  );
}
