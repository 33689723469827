import {
  Backdrop,
  Box,
  Button,
  FormControlLabel,
  Stack,
  styled,
  Switch,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import {
  PieChart,
  PieChartSlotProps,
  blueberryTwilightPalette,
  pieArcLabelClasses,
  blueberryTwilightPaletteDark,
  blueberryTwilightPaletteLight,
} from "@mui/x-charts";
import { StableColorMap } from "../../../../util/colormap";
import { LabelMappings, LabelValue } from "../../../../api/apimodels";
import { arrayToDict } from "../../../../util/dict";
import { ChangeEvent, useRef, useState } from "react";

export type LabelChartProps = {
  label: string;
  labels: LabelMappings | undefined;
  rows: [string, number][];
  labelColorFn: StableColorMap;
  maxChipWidth?: string;
  sx?: SxProps<Theme>;
};

const TopBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

type LabelChartDetailsProps = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  rows: [string, number][];
  lookup: Record<string, LabelValue>;
  name: string;
};

function abbrLabel(str: string | undefined, maxChars: number = 30, ellipsis = "..."): string {
  const s = str ?? "";
  if (s.length > maxChars) {
    return s.substring(0, maxChars - 3) + ellipsis;
  }
  return (
    s +
    Array.from({ length: maxChars - s.length })
      .map((m) => " ")
      .join("")
  );
}

function LabelChartDetails({ open, setOpen, rows, lookup, name }: LabelChartDetailsProps) {
  const theme = useTheme();
  const sum = rows.reduce((a, [_, r]) => r + a, 0);
  const [percent, setPercent] = useState(true);
  const isDark = theme.palette.mode === "dark";
  const ref: any = useRef();

  function onChangePercent(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    e.preventDefault();
    setPercent(!percent);
  }

  const arcLabel = percent ? (item: any) => `${Math.round((100 * item.value) / sum)}%` : (item: any) => item.value;

  const arcLabelMinAngle = percent ? 20 : 24;

  return (
    <TopBackdrop open={open} onClick={() => setOpen(false)} ref={ref}>
      <Stack
        direction="column"
        alignItems="flex-start"
        sx={{ borderRadius: theme.shape.borderRadius, background: theme.palette.background.paper }}
        onClick={(e) => e.stopPropagation()}
      >
        <Stack direction="row" sx={{ width: "100%", pl: 3, pr: 1, mt: 3 }} alignItems="center">
          <Typography variant="h6" flexGrow={1} sx={{ textTransform: "capitalize" }}>
            {name}
          </Typography>
          <FormControlLabel
            label="%"
            control={<Switch checked={percent} name="mask-texts" onChange={onChangePercent} />}
          />
        </Stack>
        <Box sx={{ pb: 4 }}>
          <PieChart
            series={[
              {
                arcLabel,
                arcLabelMinAngle,
                data: rows.map(([id, value]) => ({
                  id: id,
                  value,
                  label: abbrLabel(lookup[id]?.name ?? id),
                })),
                innerRadius: 80,
                outerRadius: 160,
                paddingAngle: 5,
                cornerRadius: 5,
                cx: 200,
                cy: 180,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fontWeight: isDark ? 600 : 400,
                textShadow: isDark ? "rgba(0,0,0,0.90) 0px 0px 22px" : "rgba(255,255,255,0.90) 0px 0px 17px",
              },
            }}
            colors={isDark ? blueberryTwilightPaletteLight : blueberryTwilightPaletteDark}
            width={640}
            height={360}
          />
        </Box>
      </Stack>
    </TopBackdrop>
  );
}

export function LabelChart(props: LabelChartProps) {
  const { label, rows, labels, labelColorFn, maxChipWidth = "20em", sx } = props;
  const data = labels ? labels[label] : undefined;
  const name = data ? data.name : label;
  const lookup = data && data.values ? arrayToDict(data.values, "value") : undefined;
  const [detailsOpen, setDetailsOpen] = useState(false);
  let sortedRows = rows;
  try {
    sortedRows = labels && lookup ? rows.toSorted((a, b) => lookup[a[0]].order - lookup[b[0]].order) : rows;
  } catch (e) {
    console.warn("Missing label descriptor", e);
  }
  // console.log({data, label, lookup, sortedRows});
  const slotProps: PieChartSlotProps = {
    legend: {
      hidden: true,
    },
  };
  //  const palette: string[] = rows.map(([id, value]) => labelColorFn(id));
  return (
    <Stack sx={sx} direction="column" justifyContent="flex-start" alignItems="center">
      <Typography sx={{ textTransform: "capitalize" }}>{name}</Typography>
      <div onClick={(e) => setDetailsOpen(true)}>
        <PieChart
          series={[
            {
              data: sortedRows.map(([id, value]) => ({
                id: id,
                value,
                label: (lookup && lookup[id] ? lookup[id].name : id) + "",
              })),
              innerRadius: 40,
              outerRadius: 80,
              paddingAngle: 5,
              cornerRadius: 5,
              cx: 100,
              cy: 112,
            },
          ]}
          colors={blueberryTwilightPalette}
          width={200}
          height={225}
          slotProps={slotProps}
        />
      </div>
      {detailsOpen && (
        <LabelChartDetails
          open={detailsOpen}
          setOpen={setDetailsOpen}
          name={name}
          rows={sortedRows}
          lookup={lookup ?? {}}
        />
      )}
    </Stack>
  );
}
